import { Component } from '@angular/core';

@Component({
  selector: 'app-view-sub-category',
  templateUrl: './view-sub-category.component.html',
  styleUrl: './view-sub-category.component.scss'
})
export class ViewSubCategoryComponent {

}
