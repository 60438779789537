
<app-navbar-style-three></app-navbar-style-three>
<br>
<div class="section-title">
    <h4>Create Sub Category</h4>
</div>

<section class="order-tracking-area">
    <div class="container">
        <div class="order-tracking-content">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="form-group">
                    <label>Select Category </label>
                    <select #subCategory class="form-select" 
                    (change)="selectCategory(subCategory.value)">
                    <option>Select Category</option>
                    <option *ngFor="let category of categoryList" value="{{category.id}}">
                        {{category.name}}
                    </option>
                </select>
                </div>

                <div class="form-group">
                    <label>Enter Sub Category Name</label>
                    <textarea required ngModel name="subcategory" #subcategory="ngModel" class="form-control" id="description" placeholder="Enter Sub Category"></textarea>
                </div>

                <button type="submit" class="default-btn">CREATE</button>
            </form>
        </div>
    </div>
</section>
