import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  table='dialog';
  token = localStorage.getItem('token');

  
  checkTokenExpiration(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+this.table+'/checktokenexpire?token='+this.token,details);
  }
  subscribeNumber(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+this.table+'/submitpin?token='+this.token,details);
  }




  requestOtp(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+this.table+'/requestotpinsubscription?token='+this.token,details);
  }
}
