import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { customerModel } from '../../model/customerModel';
import { UserloginService } from '../../common_service/userlogin.service';

@Component({
  selector: 'app-view-change-password',
  templateUrl: './view-change-password.component.html',
  styleUrl: './view-change-password.component.scss'
})
export class ViewChangePasswordComponent {
  customerModel!:customerModel;
  constructor(private userlogin:UserloginService) {
    userlogin.getCustomerDetails().subscribe(res=>{
      this.customerModel = res.data;
      console.log("customer data "+this.customerModel);
    })

  }
  changePassword(email:any,curpassword:any,password:any,confirmPassword:any){
    if(curpassword=='' || password=='' || confirmPassword=='' ){
      Swal.fire({
        title: "Please fill required feilds!",
      }).then((result) => {
      })
    }
    else if(password!=confirmPassword){
      Swal.fire({
        title: "New Password and Confirm Password should be same!",
      }).then((result) => {
      })
    }else{
      this.customerModel = new customerModel(this.customerModel.id,email,password,"","");
      this.userlogin.updatePasswordUser(this.customerModel).subscribe(res=>{
        Swal.fire({
          title:res.message,
        }).then((result) => {
        })

        if(res.status =='success'){
          location.reload();
        }
      })
    }
  }
}
