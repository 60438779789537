<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <p>{{Content.paragraph}}</p>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                <div class="single-products-box">
                    <div class="products-image" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image" style="height: 200px;width: 200px;">
                        </a>
                        <ul class="products-button">
                            <li><a (click)="addToWishList(Content.id)"><i class='bx bx-heart'></i></a></li>
                            <!--li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li-->
                            <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                            <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li>
                        </ul>
                        <!--button type="submit" (click)="addToCart(Content.id)" class="add-to-cart-btn" style="border:none;"> <i class="bx bx-cart"></i> Add To Cart</button-->
                        <!--a routerLink="/cart" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a-->
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <div class="price">
                            <span class="new-price">{{Content.newPrice}}</span>
                            <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                        </div>
                        
                    </div>
                    <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                    <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>