<app-navbar-style-three></app-navbar-style-three>

<div class="section-title" >
    <h2>All Pending Orders</h2>
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <!--a routerLink="/admin/add-category" class="btn btn-primary gap-2">
                Add New
            </a>
            <br><br-->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <input type="text" style="padding:8px;margin:15px auto;width:30%;"
                        placeholder="Type to filter by Invoice Number..."
                        (keyup)="updateFilter($event)" />
                    <ngx-datatable #table class="material" [columns]="cols" [columnMode]="ColumnMode.force"
                        [headerHeight]="50" [footerHeight]="50" rowHeight="auto" 
                        [rows]="rows.slice(page.pageNumber * page.size, (page.pageNumber + 1) * page.size)"
                        [limit]="page.size">

                        <ngx-datatable-column name="Invoice Number" prop="invoiceNumber">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                    <div class="font-semibold">#{{ row.invoiceNumber }}</div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Order ID" prop="order_id">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                    <div class="font-semibold">{{ row.order_id }}</div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Created Date" prop="invoiceCreatedDate">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="font-semibold">{{row.invoiceCreatedDate }}</div>
                                
                            </ng-template>
                        </ngx-datatable-column>

                      

                        <ngx-datatable-column name="Customer Name" prop="username">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                    <div class="font-semibold">{{ row.username }}</div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        
                        <ngx-datatable-column name="Order Tracking Number" prop="actions">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                    
                                    <input type="text" placeholder="Order Tracking" id="trackingNumber" #trackingNumberInput required style="width: 150px;padding-right: 5px;">
                                    <button type="button" class="btn btn-primary" (click)="updateOrderStatus(row.order_id, trackingNumberInput.value)">
                                        Deliver
                                    </button>
                                
                            </ng-template>
                        </ngx-datatable-column>
                       

                    </ngx-datatable>
                    <div class="pagination-controls">
                        <button (click)="prevPage()" [disabled]="page.pageNumber === 0">Previous</button>
                        <span>{{page.pageNumber + 1}} / {{totalPages}}</span>
                        <button (click)="nextPage()" [disabled]="page.pageNumber >= totalPages - 1">Next</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</section>

