import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-software-product',
  templateUrl: './edit-software-product.component.html',
  styleUrl: './edit-software-product.component.scss'
})
export class EditSoftwareProductComponent {

}
