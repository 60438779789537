<!--app-navbar-style-one></app-navbar-style-one-->
<app-navbar-style-two></app-navbar-style-two>

<!--app-hometwo-banner></app-hometwo-banner-->

<!--app-partner></app-partner-->

<!--Featured Items-->
<section class="products-area pt-100 pb-70">
    <app-new-products></app-new-products>
</section>

<!--app-deal-in-this-week></app-deal-in-this-week-->

<!--Top Rated Items-->
<!--div class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</div-->

<!--app-facility-style-two></app-facility-style-two-->

<!--Top Selling Products-->
<!--div class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</div-->

<!--app-testimonials></app-testimonials-->

<!--app-blog></app-blog-->

<!--app-subscribe></app-subscribe-->

<!--div class="modal fade" id="productsQuickView2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                        <button type="button" class="close2" data-bs-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true"><i class='bx bx-x' style="font-size:28px;"></i></span>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="loginFormDisplay">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;">Login</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                                <div class="form-group">
                                    <label>Enter Email</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>
                
                                
                                <button type="submit"  (click)="loginToSystem(email.value,password.value)">LOGIN</button>
                            </form>
                            <br>
                            <h6 style="text-align: center;color: rgb(3, 3, 52);">OR</h6>
                            
                            <h6 style="text-align: center;color: rgb(3, 3, 52);padding-bottom: 20px;">
                                <button class="tiltle" (click)="registerClicked()"> Create an Account</button>
                            </h6>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!loginFormDisplay">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;">Register</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                               
                                <div class="form-group" >
                                    <label>Enter Email *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="otp" placeholder="Enter Email">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Password *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>

                                <button type="submit" (click)="registerUser(email.value,password.value)" *ngIf="!registerButtonClicked">REGISTER</button>
                                <div  *ngIf="registerButtonClicked">
                                    <div class="form-group">
                                        <label>Enter Email Verification Code *</label>
                                        <input required minlength="3" maxlength="50" ngModel name="emailverification" type="text" #emailverification="ngModel" class="form-control" id="emailverification" placeholder="Enter Email Verification Code">
                                    </div>
    
                                    <button type="submit" (click)="verifyAccount(emailverification.value)" >VERIFY</button>

                                </div>
                               
                                
                               
                            </form>
                            <br>
                            <h6 style="text-align: center;color: rgb(3, 3, 52);">OR</h6>
                            
                            <h6 style="text-align: center;color: rgb(3, 3, 52);padding-bottom: 20px;">
                                <button class="tiltle" (click)="registerClicked()">Login</button>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->