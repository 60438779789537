<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<section class="contact-area ">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2>tedismart.com Privacy Notice</h2>
                    <h3>Last updated: 1st July 2022.</h3>
                    <p>We know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This Privacy Notice describes how tedismart.com and its affiliates (collectively "South Asian Technologies") collect and process your personal information through tedismart.com, satlretail.biz & satl.biz websites, devices, products, services provided online and applications that reference this Privacy Notice (together "Tedismart Services").<b> By using Tedismart Services, you are consenting to the practices described in this Privacy Notice.</b>
                </p>
                <br>
                <h3>For What purpose does tedismart.com use your personal information.</h3>
                <p>We use your personal information to operate, provide, develop, and improve the products and services that we offer our customers. These purposes include:</p>
                <ul>
                    <li><b>Purchase and delivery of products and services.</b> We use your personal information to take and handle orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.</li>
                   <br> <li><b>Provide, troubleshoot, and improve Tedismart Services.</b>We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the Amazon Services.</li>
                   <br> <li><b>Recommendations and personalization.</b>We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with smart home services.</li>
                   <br> <li><b> Comply with legal obligations.</b>In certain cases, we collect and use your personal information to comply with laws. For instance, we collect establishment and bank account information for identity verification purposes.</li>
                    <br><li><b>Communicate with you.</b>We use your personal information to communicate with you in relation to tedismart Services via different channels (e.g., by phone, email, chat).</li>
                    <br><li><b>Advertising.</b>We use your personal information to display interest-based ads for features, products, and services that might be of interest to you. We do not use information that personally identifies you to display interest-based ads.</li>
                    <br><li><b>Fraud Prevention and Credit Risks.</b>We use personal information to prevent and detect fraud and abuse in order to protect the security of our customers, Amazon, and others. We may also use scoring methods to assess and manage credit risks.</li>
                    
                </ul>
                <br>
                <h2>What about cookies and other identifiers.</h2>
                <p><b>We use cookies, pixels, and other technologies (collectively, "cookies") to recognize your browser or device, learn more about your interests, and provide you with essential features and services and for additional purposes, including:</b></p>
                <ul>
                    <li>Recognizing you when you sign-in to use our services. This allows us to provide you with product recommendations, display personalized content, recognize you.</li>
                   <br> <li> Keeping track of your specified preferences. This allows us to honour your preferences.</li>
                   <br><li>Keeping track of items stored in your shopping basket.</li>
                   <br><li>Conducting research and diagnostics to improve our content, products, and services.</li>
                   <br><li>Preventing fraudulent activity.</li>
                   <br><li>Improving security.</li>
                   <br><li>Delivering content, including ads, relevant to your interests from our sites.</li>
                   <br><li>Reporting. This allows us to measure and analyze the performance of our services.</li>
                </ul>
                <br>
                <h2>What personal information about customer does tedismart collect?</h2>
                <p><b>We collect your personal information in order to provide and continually improve our products and services.
                    Here are the types of personal information we collect:</b></p>
                    <ul>
                        <li><b>Information You Give Us:</b>:We receive and store any information you provide in relation to tedismart Services. You can choose not to provide certain information, but then you might not be able to take advantage of many of our Services.</li>
                      <br>  <li><b>Automatic Information:</b>We automatically collect and store certain types of information about your use of tedismart Services, including information about your interaction with products, content and services available. Like many websites, we use "cookies" and other unique identifiers, and we obtain certain types of information when your web browser or device accesses tedismart Services and other content served on behalf of tedismart by other websites.</li>
                    <br><li><b>Information from Other Sources:</b>We might receive information about you from other sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily.</li>
                    <br><li><b> At present tedismart.com </b>or any of its affiliates shall not save any information pertaining to payment instruments used to purchase goods/services from our site.</li>
                    
                    </ul>
                    <br>
                    <h2>Conditions of use</h2>
                    <p>If you choose to use tedismart Services, your use and any dispute over privacy is subject to this Notice and our conditions of use, including limitations on damages, resolution of disputes, and application of the law of Democratic Republic of Sri Lanka. If you have any concern about privacy at tedismart, please contact us with a thorough description, and we will try to resolve it. Our business changes constantly, and our Privacy Notice will change also. You should check our websites frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.</p>
                    <p>Welcome to tedismart.com. satlretail.biz Services and/or its affiliates provide website features and other products and services to you when you visit tedismart.com, use TeDiSmart products or services, use tedismart applications for mobile, or use software provided by tedismart in connection with any of the foregoing (collectively, "tedismart Services"). By using the tedismart Services, you agree, on behalf of yourself and all members of your household and others who use any Service under your account, to the aforesaid conditions.</p>
                </div>
              
                
            </div>
        </div>
    </div>
</section>
