import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../common_service/payment.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-blog-grid',
    templateUrl: './blog-grid.component.html',
    styleUrls: ['./blog-grid.component.scss']
})
export class BlogGridComponent implements OnInit {

    constructor(private route: ActivatedRoute,private paymentService:PaymentService) { }
   // resultIndicator=53704a2f43c94277&sessionVersion=6ac8755c10
    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            const resultIndicator = params.get('resultIndicator');
            const sessionVersion = params.get('sessionVersion');
            let details = {
                'session_version':sessionVersion,
                'success_indicator':resultIndicator
              }
              const uploadFormData = new FormData();
              uploadFormData.append('details', JSON.stringify(details));
            this.paymentService.checkpaymentstatus(uploadFormData).subscribe(res=>{
                let status = res.status;
               console.log("pahyment status "+status);

            })
            
          });
    }

 

}