import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class AddToCartService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  token = localStorage.getItem('token');
  getCartDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'cart/getusercart?token='+this.token,details);
  }

  placeOrder(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'cart/placeorder?token='+this.token,details);
  }

  //************** Used end *******************8 */
  addToCart(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/addtocart',details);
  }

  

  changeQuantity(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/quantitychange',details);

  }

  removeItem(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/removeitem',details);

  }



 
}
