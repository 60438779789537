export class VerificationModel {
    email:string;
    email_token: string;
    mobile: string;
    otp: number;
    
   
    constructor(
        email:string,
        email_token:string,
        mobile: string,
        otp: number,
        

    ) {
        this.email=email;
        this.mobile = mobile;
        this.otp = otp;
        this.email_token = email_token;
    
       
    }

    getOtp(): number {
        return this.otp;
    }
    setOtp(otp: number): void {
        this.otp = otp;
    }
    getEmail_token(): string {
        return this.email_token;
    }
    setEmail_token(email_token: string): void {
        this.email_token = email_token;
    }
 
    getMobile(): string {
        return this.mobile;
    }
    setMobile(mobile: string): void {
        this.mobile = mobile;
    }
  
    getEmail(): string {
        return this.email;
    }
    setEmail(email: string): void {
        this.email = email;
    }


}
