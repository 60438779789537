import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductService } from '../../common_service/product.service';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';
import { ProductServiceService } from '../../admin_service/product-service.service';
import { ProductPriceModel } from '../../model/ProductPricesMode';

@Component({
    selector: 'app-upcoming-products',
    templateUrl: './upcoming-products.component.html',
    styleUrls: ['./upcoming-products.component.scss']
})
export class UpcomingProductsComponent implements OnInit {
    productPriceModel!:ProductPriceModel;
    singleProductsBox:Array<any> =[];
    constructor(private productService:ProductService,private addToWishListService:AddToWishListService,private adminProductService:ProductServiceService) {
        productService.getTopSellingProducts().subscribe(res=>{
            this.singleProductsBox = res;
        })  
        
        this.adminProductService.getProductsPricesInHome().subscribe(res=>{
            this.productPriceModel = res.data;
           })
     }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Zone Alarm Subscription",
            paragraph: "Zonealarm Mobile Security is the ultimate antivirus security solution for your mobile device."
        }
    ]
    /*singleProductsBox = [
        {
            mainImg: 'assets/img/products/products1.jpg',
            title: 'Rivet Farr Lotus',
            newPrice: '$150.00',
            oldPrice: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products2.jpg',
            title: 'Modern Leather Soft',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products3.jpg',
            title: 'Mesh Computer Desk',
            newPrice: '$223.99',
            oldPrice: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products2.jpg',
            title: 'Modern Leather Soft',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            detailsLink: 'simple-product'
        }
    ]*/

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}