import { Component, OnInit } from '@angular/core';
import { ProductServiceService } from '../../admin_service/product-service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-blog-right-sidebar',
    templateUrl: './blog-right-sidebar.component.html',
    styleUrls: ['./blog-right-sidebar.component.scss']
})
export class BlogRightSidebarComponent implements OnInit {

    softwareProductList:Array<any> =[];
    images:Array<any>=[];
    constructor(private productService:ProductServiceService) {
        productService.getAllSoftwareDetails().subscribe(res=>{
            this.softwareProductList = res;
            
            console.log("this.softwareProductList "+JSON.stringify(this.softwareProductList));
        })

    
     }

     productsDetailsImageSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items:3,
			},
			768: {
				items: 4,
			},
			1200: {
				items: 4,
			}
		}
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Blog Right Sidebar'
        }
    ]
  
    blogRightSidebar: number = 1;

}