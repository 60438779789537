import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginServiceService } from '../../admin_service/login-service.service';
import { customerModel } from '../../model/customerModel';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrl: './admin-login.component.scss'
})
export class AdminLoginComponent {
  customerModel!: customerModel;
  constructor(private loginService: LoginServiceService) { }
  submit(form) {
    var email = form.email;
    console.log(email);

    var password = form.password;
    console.log(password);


    this.customerModel = new customerModel(0, email, password, '', '');
    this.loginService.loginAdmin(this.customerModel).subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Success!',
        }).then((result) => {
          var token = res.token;
          localStorage.setItem('token', token);
          localStorage.setItem('accShow', 'show');
          window.location.href = "/admin/dashboard";
        })
      } else {

        Swal.fire({
          title: res.message,
        }).then((result) => {
          location.reload();
        })

      }
    })
  }

}
