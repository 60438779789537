<app-navbar-style-two></app-navbar-style-two>
<br><br>
<div class="row">
    <div class="col-lg-4">
    </div>
    <div class="col-lg-4 col-sm-12 col-md-12">
        <div class="login-form" style="padding: 2%;">
            <h4 style="text-align: center;">Zone Alarm {{selectedPackage}} Subscription</h4>
            <br>
            <form #contactForm="ngForm">
                <div class="form-group">
                    <label>Enter Dialog Mobile Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <input class="input-group-text" class="form-control" value="+94" readonly style="width: 50px;">
                        </div>
                        <input type="text" class="form-control" id="mobile_number" name="mobile_number" value="" #mobile_number
                            placeholder="Dialog  Mobile Number">
                    </div>
                   
                    <button class="btn btn-outline-primary-2" id="sendotpbtn" type="submit" (click)="requestOtp(mobile_number.value)" *ngIf="!requestOtpClicked">Request OTP</button>
                </div>
                <div *ngIf="requestOtpClicked">
                    <div class="form-group">
                        <label>Enter OTP</label>
                        <input type="text" class="form-control" id="otpnumber" name="otpnumber" value="" #otpnumber
                            placeholder="OTP Number">
                    </div>
                    <button type="submit" (click)="subscribe(mobile_number.value,otpnumber.value)">Subscribe</button>
                </div>
                
                <button type="submit" (click)="viewMySubscription()"  id="subscriptionButton" >View My Subscription</button>
            
            </form>
        </div>
    </div>
    <div class="col-lg-4">
    </div>
</div>
<!--div class="summary">
    <h3 class="summary-title">Zone Alarm Subscription</h3>
    <div class="input-group">
        <input type="text" class="form-control" id="mobile_number" name="mobile_number" value=""
            placeholder="Dialog  Mobile Number">
        <button class="btn btn-outline-primary-2" id="sendotpbtn" style="height: 40px;" type="submit"><i
                class="icon-long-arrow-right"></i></button>
    </div>
    <div class="input-group">
        <input type="text" style="width: 400px;" class="form-control" id="otpnumber" name="otpnumber" value=""
            placeholder="OTP Number">

    </div>
    <button id="playceorderbtn" type="submit" class="btn btn-outline-primary-2 btn-order btn-block">
        Subscribe

    </button>


    <a id="processtocheckout"> <button id="gotocheckoutbtn" type="submit"
            class="btn btn-outline-primary-2 btn-order btn-block">
            Process to Checkout

        </button> </a>

    <br>
    <br>
    <a id="subscriptionButton" class="btn btn-outline-primary-2 btn-order btn-block"
        href="https://tedismart.com/dashboard.php#tab-subscription" target="_blank">
        View My Subscription

    </a>

</div-->