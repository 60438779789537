import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from './backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

   token = localStorage.getItem('token');
  getAllCustomers(details:any){
    
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'admin/customer/getallcustomer?token='+this.token,details);
  }

}
