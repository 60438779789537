import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from './backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class MaserfilesService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }


  create(details:any,table:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+table+'/create',details);
  }

  update(details:any,table:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+table+'/update',details);
  }

  getAll(table:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+table+'/getall');
   
  }

  delete(table:any,id:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+table+'/delete',id);
  }

  getDetails(table:any,id:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+table+'/getdetails?id='+id);
  }
}
