<app-navbar-style-three></app-navbar-style-three>

<div class="section-title">
    <h2>Add Software Product Details</h2>

</div>


<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>Title</label>
                                        <input required minlength="3" maxlength="50" ngModel name="productTitle"
                                            type="text" #productTitle="ngModel" class="form-control" id="productTitle"
                                            placeholder="Product Title">

                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <!--div class="form-group mb-3">
                                            <label>Price</label>
                                            <input required ngModel name="productPrice" type="text" #productPrice="ngModel"
                                                class="form-control" id="productPrice" placeholder="Product Price">
                                        </div-->
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <label>Short Description</label>
                                        <input required ngModel name="productDesciption" type="text"
                                            #productDesciption="ngModel" class="form-control" id="productDesciption"
                                            placeholder="Short Desciption">

                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" (click)="submit(contactForm.value)" class="default-btn"
                                        [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save
                                        Product</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-title">
    <h2>Add Product Images</h2>
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Main Image</label>
                                        <br>
                                        <input type="file" class="file-input" #fileUploadVehicleReg required
                                            accept=".jpg, .jpeg, .png" (change)="onFileSelectedMainImg($event)">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="saveMainImage(fileuploadotherimages)"><i
                                                class='bx bx-plus'></i>Add Main Image</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Other Images</label>
                                        <br>
                                        <input type="file" class="file-input" #fileuploadotherimages required
                                            accept=".jpg, .jpeg, .png" (change)="onFileSelectedOtherImages($event)">

                                        <ul *ngFor="let Content of otherImagesNameList;" class="reduce-space">
                                            <li [innerHTML]="Content">{{Content}} </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn"
                                            (click)="addImagesOtherToList(fileuploadotherimages)"><i
                                                class='bx bx-plus'></i>Add To Images List</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>