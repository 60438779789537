import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { SubscriptionService } from '../../common_service/subscription.service';

@Component({
  selector: 'app-view-zonealarm-subscription',
  templateUrl: './view-zonealarm-subscription.component.html',
  styleUrl: './view-zonealarm-subscription.component.scss'
})
export class ViewZonealarmSubscriptionComponent {
  logOutClicked(){
    localStorage.setItem("token","empty");
    localStorage.setItem("accShow","notShow");
    window.location.href="/";
  }
  userCartDetailsList:any;
  userCartDetailsListSize:number=0;
  termsAccepts:boolean=false;
  constructor(private subscriptionService:SubscriptionService) { 
      let token = localStorage.getItem("token");
      let details = {
          'token':token,
        }
      const uploadFormData = new FormData();
      uploadFormData.append('details', JSON.stringify(details));
      this.subscriptionService.getSubscriptionDetails(uploadFormData).subscribe(res=>{
          this.userCartDetailsList = res.data;
          if(this.userCartDetailsList!=null){
              this.userCartDetailsListSize=1;
          }
          console.log("this.userCartDetailsList "+JSON.stringify(this.userCartDetailsList));
      })
     
  }

  ngOnInit(): void {
  }

  unSubscribed(){
    this.subscriptionService.unsubscribe(this.userCartDetailsList).subscribe(res=>{
      Swal.fire({
        title: res.message,
      }).then((result) => {
        
      })
    })
  }

  
}
