import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  
  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  token = localStorage.getItem('token');
  getSubscriptionDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'subscription/getcustomersubscription?token='+this.token,details);
  }

  unsubscribe(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'subscription/unsubscription?token='+this.token,details);
  }

  getUserAllSubscriptionDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'subscription/getallcustomersubscription?token='+this.token,details);
  }

  getUserAllSubscriptionDetailsForAdmin(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'admin/subscription/getallcustomersubscriptionofAdmin?token='+this.token,details);
  }

  getUserAllUnSubscriptionDetailsForAdmin(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'admin/subscription/getallcustomerunsubscriptionofAdmin?token='+this.token,details);
  }
}
