import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackendURLService {

  constructor() { }
 
  apiLocalUrlLocal = "https://tradiov2.uc.r.appspot.com/";
  apiLocalUrlSpringSS = "http://localhost:8084/";

  
  apiLocalSystemSpring = "https://zonealarmservice.tedismart.com:8084/system/user/";
  //apiLocalUrl = "http://localhost:8084/";
  apiLocalUrl="https://zonealarmservice.tedismart.com:8084/";
  apiLocalSystem= "https://zonealarmservice.tedismart.com:8084/system/user/";
 
 
  
}
