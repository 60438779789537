import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-shop-right-sidebar-page-one',
    templateUrl: './shop-right-sidebar-page-one.component.html',
    styleUrls: ['./shop-right-sidebar-page-one.component.scss']
})
export class ShopRightSidebarPageOneComponent implements OnInit {

    constructor(private userlogin: UserloginService, private route: ActivatedRoute) { }

    hashVal: any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.hashVal = params['hash'];
        })
    }

    submit(form) {
        var newPassword = form.newPassword;
        console.log(newPassword);

        var password = form.password;
        console.log(password);

        if (newPassword == password) {
            let details = {
                'newPassword': newPassword,
                'password': password,
                'hash': this.hashVal
            }
            const uploadFormData = new FormData();
            uploadFormData.append('details', JSON.stringify(details));
            this.userlogin.updatePasswordForgot(uploadFormData).subscribe(res => {
                var status = res.status;
                if (status == 'success') {
                    Swal.fire({
                        title: 'Success!',
                    }).then((result) => {
                        localStorage.setItem('token', 'empty');
                        localStorage.setItem('accShow', 'notshow');
                        window.location.href = "/";
                    })
                } else {
                    Swal.fire({
                        title: 'Invalid User',
                    }).then((result) => {
                        location.reload();
                    })
                }
            })
        } else {
            Swal.fire({
                title: 'Confirm password should be same as new password',
            }).then((result) => {

            })
        }



    }




}