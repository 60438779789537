import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductServiceService } from '../../admin_service/product-service.service';

@Component({
    selector: 'app-shop-full-width-page-one',
    templateUrl: './shop-full-width-page-one.component.html',
    styleUrls: ['./shop-full-width-page-one.component.scss']
})
export class ShopFullWidthPageOneComponent implements OnInit {

    constructor(private productService:ProductServiceService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Profile Authentication'
        }
    ]

    productHighlightList:Array<any> =[];
    addToProductHightlightList(){
        if(this.productHighlights.length==0){
            Swal.fire({
                title: 'Please enter product highlight',
              }).then((result) => {
                
              })     
        }else{
            console.log("this.productHighlights "+this.productHighlights);
        this.productHighlightList.push(this.productHighlights);
        this.productHighlights= '';
        }
        

    }

    deleteHighlight(highlight:any){

        const index = this.productHighlightList.indexOf(highlight);
        console.log("delete "+index);
        if (index > -1) {
            this.productHighlightList.splice(index, 1); // Remove one item at the found index
        }
    }

    productHighlights:string='';
    specificationType:string='';

    specificationValue:string='';
    productSpecificationList:Array<any> =[];



    deleteSpedification(specification: any) {
        const index = this.productSpecificationList.indexOf(specification);
        if (index > -1) {
            this.productSpecificationList.splice(index, 1); // Remove one item at the found index
        }
    }

    addSpecification(){
        if(this.specificationType.length==0 || this.specificationValue.length==0 ){
            Swal.fire({
                title: 'Please enter product specification',
              }).then((result) => {
                
              })   
        }else{
            console.log("this.productHighlights "+this.productHighlights);
            let spObj = {
                'type':this.specificationType,
                'value':this.specificationValue
            }
            this.productSpecificationList.push(spObj);
            this.specificationType= '';
            this.specificationValue= '';
        }
     


    }
  

    submit(form){
        var productTitle = form.productTitle;
        console.log(productTitle);
        var productDesciption = form.productDesciption;
        console.log(productDesciption);
        var productModel = form.productModel;

       
        
        let details = {
            'id':productModel,
            'status':'NEW',
            'mainImg':'',
            'title':productTitle,
            'model':productModel,
            'shortDescription':productDesciption,
            'detailsLink':'admin/simple-product-edit/',
            'descriptionList':this.productHighlightList,
            'specifications':this.productSpecificationList,
            
           
        }

        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedFileVehicelReg);
        uploadFormData.append('details', JSON.stringify(details));
        this.productService.saveDetails(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully added the product!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with adding the product!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })
       


    }

    fileSizeInBytes:any;
    public selectedFileVehicelReg: any = File;
    onFileSelectedVehicleReg(event: any) {
      const file = event.target.files[0];
      this.fileSizeInBytes = file.size;
      console.log("this.fileSizeInBytes "+this.fileSizeInBytes);
      this.selectedFileVehicelReg = file;
     /* if(this.fileSizeInBytes>10485760){
        console.log("this.fileSizeInBytes 22 "+this.fileSizeInBytes);
        Swal.fire({
          title: 'Size of image should be less than 10240kb!',
        }).then((result) => {
        })
      }else{
        this.selectedFileVehicelReg = file;
      }*/
     
  
  
    }
}