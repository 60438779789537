import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class UserloginService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  table='customer';


  loginUser(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+this.table+'/login',details);
  }

  registerUser(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+this.table+'/register',details);
  }

  verification(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+this.table+'/verification',details);
  }


  getCustomerDetails(){
    let token = localStorage.getItem('token');
    return this.httpClient.get<any>(this.backendService.apiLocalSystem+'customer_profile/getdetails?token='+token);

  }

  updatePasswordUser(details:any){
    let token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'customer_profile/changepassword?token='+token,details);
  }


  forgotPasswordZone(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'recoverypassword',details);
  }

  updatePasswordForgot(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'updatenewpassword',details);
  }

  //******************************Used END ************************* */
  
 

  saveAddress(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/saveaddress',details);
  }

  getUserAddresses(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getaddresses',details);
  }

  getAddressById(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getaddressbyId',details);
  }

  updateAddress(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/updateaddress',details);
  }

  getUserDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getuserdetailsforedit',details);
  }

  forgotPassword(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrlLocal+'recoverypassword',details);
  }

  
}
