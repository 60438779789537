
<!-- Start Main Banner Area -->
<div class="home-slides-two" style="background-image: linear-gradient(90deg, white 0%, white 100%, white 100%, white 100%);">
    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
            <div class="banner-area">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-12">
                            <div class="banner-content">
                                <h1 style="padding-left: 10%;">{{Content.title}}</h1>
                                <p>{{Content.shortDescription}}</p>
                                <a routerLink="/{{Content.detailsLink}}" class="default-btn"><i class="bx bx-cart"></i> Shop Now</a>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-12"></div>
                        <div class="col-lg-6 col-md-12">
                            <div style="padding-top: 0;margin-top: 0;">
                                <img [src]="Content.mainImg" alt="image"  >
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-12"></div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- End Main Banner Area -->
<!--div class="home-banner-area home-banner-area-two">
    <div class="container-fluid">
        <div class="home-slides">
            <owl-carousel-o [options]="homeSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
                    <div class="main-banner-area">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <div class="main-banner-content">
                                    <span class="sub-title">{{Content.subTitle}}</span>
                                    <h1>{{Content.title}}</h1>
                                    <p>{{Content.shortDescription}}</p>
                                    <a routerLink="/{{Content.detailsLink}}" class="default-btn"><i class="bx bx-cart"></i> Shop Now</a>
                                </div>
                            </div>
        
                            <div class="col-lg-7 col-md-12">
                                <div class="main-banner-image text-center" >
                                    <img [src]="Content.mainImg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

</div-->
