import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { SupportService } from '../../common_service/support.service';

@Component({
    selector: 'app-customer-service-page',
    templateUrl: './customer-service-page.component.html',
    styleUrls: ['./customer-service-page.component.scss']
})
export class CustomerServicePageComponent implements OnInit {

    constructor(private supportService:SupportService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/service.jpg',
            title: 'Service Support'
        }
    ]

    serviceType(event: Event): void {
        const selectElement = event.target as HTMLSelectElement;
        this.serviceTypeValue = selectElement.value;
        console.log(this.serviceTypeValue);  // Here you can use the selected value as needed
      }

    serviceTypeValue: string = 'General Service';
    submit(form){
        var name = form.name;
        var email = form.email;
        var number = form.number;
        var message = form.message;
        var token = localStorage.getItem('token');
        
        let details = {
            'user_email':email,
            'user_name':name,
            'mobile_number':number,
            'message':message,
            'service_type':this.serviceTypeValue,
            'token':token,
            'type':'Service Support',
            'subject':'Service Support'
    
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.supportService.sendServiceSupportEmail(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                   
                  })
            }else{
             Swal.fire({
                 title: 'Error',
            }).then((result) => {
                      
            })
                
            }
        })

    }

}