import { Component, ViewChild } from '@angular/core';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ProductServiceService } from '../../admin_service/product-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-all-products',
  templateUrl: './list-all-products.component.html',
  styleUrl: './list-all-products.component.scss'
})
export class ListAllProductsComponent {
  rows = [];
  loading = false;
  actionsTemplate: any;
  cols = [
    { prop: 'id', name: 'ID' },
    { prop: 'title', name: 'Title' },
    { prop: 'description', name: 'Description' },
    { prop: 'createdDateTime', name: 'Created At' },
    //{ prop: 'updated', name: 'Last Updated At' },
    { prop: 'actions', name: 'Actions' }
  ];
  categoryList:Array<any>=[];
  subcategoryList:Array<any>=[];
  constructor(private maserfilesService: MaserfilesService,private productService:ProductServiceService) {
    maserfilesService.getAll('category').subscribe(res => {
      this.categoryList = res;
     
      console.log("main categories " + JSON.stringify(res));
    })

    maserfilesService.getAll('subcategory').subscribe(res => {
      this.subcategoryList = res;
     
      console.log("this.subcategoryList  categories " + JSON.stringify(this.subcategoryList ));
    })

    productService.getAllProductsDetails().subscribe(res=>{
      this.rows = res;
      console.log("All Products " + JSON.stringify(this.rows));
      this.page.totalElements = this.rows.length;
      this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
      this.totalPages = this.page.totalPages;
      this.temp = this.rows;
      this.sortByColumn('id');
    })
    

  }

  sortByColumn(column: string) {
    this.rows = [...this.rows].sort((a, b) => (a[column] > b[column] ? 1 : -1));
  }

  deleteRow(id: any) {
    this.productService.delete(id).subscribe(res=>{
      Swal.fire({
        title:res.message,
      }).then((result) => {
        location.reload();
      })
    })
  }

  searchType: string = 'Stock Item Name';
  selectedType: any;
  selectTypeToSearch(type: string) {
    this.searchType = type;
  }

  searchValue(value: string) {
    // Implement your search logic here
    console.log('Searching for:', value);
  }

  //****************************** */
  temp = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }



  page = {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.fetchData();
  }

  totalPages = 0;
  fetchData() {
    this.page.totalElements = this.rows.length;
    this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
    this.totalPages = this.page.totalPages;
  }

  onPageChange(event: any) {
    this.page.pageNumber = event.page;
  }

  prevPage() {
    if (this.page.pageNumber > 0) {
      this.page.pageNumber--;
      this.setPage({ offset: this.page.pageNumber });

    }
  }

  nextPage() {
    if (this.page.pageNumber < this.page.totalPages - 1) {
      this.page.pageNumber++;
      this.setPage({ offset: this.page.pageNumber });
    }
  }




  


 
}
