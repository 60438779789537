import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductServiceService } from '../../admin_service/product-service.service';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  constructor(private productService: ProductServiceService, private route: ActivatedRoute) { }

  productsSlidesOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: false,
    autoplay: false,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 3
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      },
      1200: {
        items: 4
      }
    }
  }
  singleProductsItem = [];
  searchtext: any;
  allProducts: Array<any> = [];
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log("searchtext 222 ");
      this.searchtext = params['searchtext'];
      this.getSearchDetails();
      //  this.searchInAnotherWay();
    })
  }

  getSearchDetails() {
    this.productService.getAllProductsDetails().subscribe(res => {
      this.allProducts = res;
      this.singleProductsItem = this.allProducts;
      console.log((this.allProducts.length > 0) + "searchtext  this.allProducts  ");

      if (this.allProducts.length > 0) {
        console.log("searchtext 111 ");
        this.route.params.subscribe(params => {
          console.log("searchtext 222 ");
          this.searchtext = params['searchtext'];
          console.log("searchtext " + this.searchtext);

          const selectedItem = this.productService.getProductsInTitle(this.searchtext, this.allProducts);
          this.singleProductsItem = selectedItem;

        });
      }
    })
  }

  searchInAnotherWay() {
    /*if(this.searchtext=='all'){
              console.log("searchtext "+this.searchtext);  
              this.productService.getAllDetails().subscribe(res=>{
                  this.allProducts = res;
                  //this.singleProductsItem = this.allProducts;
                  console.log("searchtext "+this.searchtext + " "+JSON.stringify(this.singleProductsItem));
              })
              Swal.fire({
                  title: "Search Product",
                  input: "text",
                  inputAttributes: {
                    autocapitalize: "off"
                  },
                  showCancelButton: true,
                  confirmButtonText: "Search",
                  showLoaderOnConfirm: true,
                  preConfirm: async (login) => {
                      console.log("loginval "+login);  
                    try {
                      const selectedItem = this.productService.getProductsInTitle(login,this.allProducts);
                      this.singleProductsItem = selectedItem;
                    } catch (error) {
                      
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                  if (result.isConfirmed) {
                   
                  }
                });
          }*/
  }

  sectionTitle = [
    {
      title: "Search Result",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
    }
  ]

}