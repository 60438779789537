import { Component, ViewChild } from '@angular/core';
import { ProductService } from '../../common_service/product.service';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import Swal from 'sweetalert2';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-list-all-sub-category',
  templateUrl: './list-all-sub-category.component.html',
  styleUrl: './list-all-sub-category.component.scss'
})
export class ListAllSubCategoryComponent {
  rows = [];
  loading = false;
  cols=[];
  categoryList:Array<any>=[];
  constructor(private maserfilesService:MaserfilesService) {
    maserfilesService.getAll('subcategory').subscribe(res=>{
      this.rows = res;
      this.page.totalElements = this.rows.length;
      this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
      this.totalPages =  this.page.totalPages;
      this.temp = this.rows;
      this.sortByColumn('id');
      console.log("sub categories "+JSON.stringify(res));
    })

    maserfilesService.getAll('category').subscribe(res=>{
      this.categoryList = res;
      console.log("main categories "+JSON.stringify(this.categoryList));
    })
   
 }

 sortByColumn(column: string) {
  this.rows = [...this.rows].sort((a, b) => (a[column] > b[column] ? 1 : -1));
}

 deleteRow(id:any){
  this.maserfilesService.delete('subcategory',id).subscribe(res=>{
    Swal.fire({
      title:res.message,
    }).then((result) => {
      location.reload();
    })
  })
 }
 
 

//****************************** */
temp = [];

@ViewChild(DatatableComponent) table: DatatableComponent;
ColumnMode = ColumnMode;

updateFilter(event) {
  const val = event.target.value.toLowerCase();

  // filter our data
  const temp = this.temp.filter(function (d) {
    return d.name.toLowerCase().indexOf(val) !== -1 || !val;
  });

  // update the rows
  this.rows = temp;
  // Whenever the filter changes, always go back to the first page
  this.table.offset = 0;
}



page = {
  size: 10,
  totalElements: 0,
  totalPages: 0,
  pageNumber: 0
};

ngOnInit() {
  this.setPage({ offset: 0 });
}

setPage(pageInfo) {
  this.page.pageNumber = pageInfo.offset;
  this.fetchData();
}

totalPages=0;
fetchData() {
  this.page.totalElements =this.rows.length;
  this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
  this.totalPages =  this.page.totalPages;
}

prevPage() {
  if (this.page.pageNumber > 0) {
    this.page.pageNumber--;
    this.setPage({ offset: this.page.pageNumber });
  }
}

nextPage() {
  if (this.page.pageNumber < this.page.totalPages - 1) {
    this.page.pageNumber++;
    this.setPage({ offset: this.page.pageNumber });
  }
}
}
