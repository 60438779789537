import { Component } from '@angular/core';
import { DialogService } from '../../common_service/dialog.service';
import { ActivatedRoute } from '@angular/router';
import { UserloginService } from '../../common_service/userlogin.service';
import { VerificationModel } from '../../model/VerificationModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrl: './select-package.component.scss'
})
export class SelectPackageComponent {
  selectedPackage:string='';
  constructor(private dialogService:DialogService,private route: ActivatedRoute,private userlogin:UserloginService ) { }

    ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.selectedPackage = params['package'];
      })
    }

  requestOtpClicked:boolean=false;
  verificationModel!:VerificationModel;
  serRef:string="NO";
  requestOtp(mobile_number:any){
    if(mobile_number==''){
      Swal.fire({
        title: 'Please enter mobile number',
      }).then((result) => {
      })
    }
    else{
      
      this.verificationModel = new VerificationModel('',this.selectedPackage,mobile_number,0);
      this.dialogService.requestOtp(this.verificationModel).subscribe(res=>{
        if(res.status=='success'){
          this.requestOtpClicked = true;
        }
        Swal.fire({
          title: res.message,
        }).then((result) => {
        })

        if(res.status=='success'&& res.data=='SUBSCRIBED'){
          window.location.href="/cart";
          
        }
        if(res.status=='success'&& res.data!='SUBSCRIBED'){
          this.serRef = res.data;
        }

       
  
      })
    }
   

  }

  subscribe(mobile_number:any,otp:any){
    this.verificationModel = new VerificationModel(this.selectedPackage,this.serRef,mobile_number,otp);
    this.dialogService.subscribeNumber(this.verificationModel).subscribe(res=>{
      Swal.fire({
        title: res.message,
      }).then((result) => {
      })

      if(res.status=='success' && res.data=='SUBSCRIBED'){
        window.location.href="/cart";
      }

    })
  }

  viewMySubscription(){
    window.location.href='view/account';
  }

}
