import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../../common_service/userlogin.service';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { CheckoutService } from '../../common_service/checkout.service';
import Swal from 'sweetalert2';
import { EMPTY } from 'rxjs';
declare var Checkout: any;
@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {

  userCartDetailsList: Array<any> = [];
  subTotalData: string = 'LKR 0.00';
  shipping: string = 'LKR 0.00';
  allTotal_value: string = 'LKR 0.00';
  orderId:any;
  constructor(private cartService: AddToCartService, private loginService: UserloginService, private checkoutService: CheckoutService) {
    let token = localStorage.getItem("token");
    let details = {
      'token': token,
    }
    const uploadFormData = new FormData();
    uploadFormData.append('details', JSON.stringify(details));
    this.checkoutService.getOrderDetails(uploadFormData).subscribe(res => {
      let status = res.status;
      console.log("this.userCartDetailsList 2 " + JSON.stringify(res));
      if (status == "success") {
        this.userCartDetailsList = res.data;
        this.subTotalData = res.subTotalPrice;
        this.shipping = res.shipping;
        this.allTotal_value = res.allTotal_value;

        this.orderId = res.order_id;
        let discountD = {
          'discount_amount':0
        }

        let vouchers = {
          'vouchers':''
        }
        let details2 = {
          'token': token,
          'order_id': this.orderId,
          'amount': res.total_checkout,
          'description': 'Place_Order',
          'discount':discountD,
          'vouchers':vouchers

        }
        console.log("details2 "+JSON.stringify(details2))
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details2));
        this.checkoutService.getCheckoutToken(uploadFormData).subscribe(res => {
          let st = res.status;
          if (st == "success") {
            this.sessionToken = res.sessionToken;
          }

        })
      } else {
        Swal.fire({
          title: 'No items in the cart!',
        }).then((result) => {

        })
      }

    })




  }

  paymentType: any;
  handleClick(type: any) {
    this.paymentType = type;
    console.log("clik" + type);
  }


  billingAddressObj: any;
  shippingAddressObj: any;
  billingAddressObjSize: number = 0;
  shippingAddressObjSize: number = 0;
  ngOnInit(): void {
    let token = localStorage.getItem('token');
    if (token == null || token == 'null') {
      Swal.fire({
        title: 'Please log into the system!',
      }).then((result) => {

      })
    } else {
      let details = {
        'token': token,
      }
      const uploadFormData = new FormData();
      uploadFormData.append('details', JSON.stringify(details));
      this.loginService.getUserAddresses(uploadFormData).subscribe(res => {
        this.billingAddressObjSize = res.billingAddressSize;
        this.shippingAddressObjSize = res.shippingAddressSize;
        console.log("bill size " + this.billingAddressObjSize);
        this.billingAddressObj = res.billingAddress;
        this.shippingAddressObj = res.shippingAddress;
      })
    }
  }

  pageTitle = [
    {
      bgImage: 'https://tedismart.com/assets/images/TopHeaderAnimation.gif',
      title: 'Checkout'
    }
  ]


  searchChange($event) {
    console.log($event);
  }

  selectedAddressShipping:string='';
  selectedShippingAddres(selectedAddressShipping:any){
    this.selectedAddressShipping = selectedAddressShipping;
    console.log("selectedAddressShipping "+JSON.stringify(selectedAddressShipping));
  }

  //Open payment gateway
  sessionToken: any;
  openGatway() {
    console.log("this.billingAddressObj " + this.billingAddressObj + (this.billingAddressObj == null));
    if (this.billingAddressObjSize == 0) {
      Swal.fire({
        title: 'Please add the billing address!',
      }).then((result) => {

      })
    } else if (this.shippingAddressObjSize == 0) {
      Swal.fire({
        title: 'Please add the shipping address!',
      }).then((result) => {

      })
    }else if(this.selectedAddressShipping==''){
      Swal.fire({
        title: 'Please select the shipping address!',
      }).then((result) => {

      })
    }
    else if (this.paymentType == null) {
      Swal.fire({
        title: 'Please select the payment method!',
      }).then((result) => {

      })
    }
    else {

      if (this.sessionToken == null) {
        Swal.fire({
          title: 'There is an issue.!',
        }).then((result) => {
  
        })
      } else {
        if (this.paymentType != null && this.paymentType == 'paypal') {
          console.info("this.sessionToken " + this.sessionToken)

          let details= {
            'selected_billing_address':this.billingAddressObj,
            'selected_shipping_address':this.selectedAddressShipping,
            'selected_payment_type':this.paymentType,
            'order_id':this.orderId
          }

          const uploadFormData = new FormData();
      uploadFormData.append('details', JSON.stringify(details));
      this.checkoutService.updateCheckOutAddress(uploadFormData).subscribe(res => {
        let status = res.status;
        if(status=='success'){
          Checkout.configure({
            session: {
              id: this.sessionToken,
            },
            interaction: {
              merchant: {
                logo: 'https://tedismart.com/assets/images/sat_tedi_logo.png'
              },
              displayControl: {
                billingAddress: 'HIDE',
                customerEmail: 'HIDE',
                orderSummary: 'SHOW',
                shipping: 'HIDE'
              }
            }
          });
          Checkout.showPaymentPage();

        }else{
          Swal.fire({
            title: 'There is an error with saving the selected details',
          }).then((result) => {
    
          })
        }
      })



          
        }
      }
    }
  }

}