import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';

@Component({
    selector: 'app-sticky-product-page',
    templateUrl: './sticky-product-page.component.html',
    styleUrls: ['./sticky-product-page.component.scss']
})
export class StickyProductPageComponent implements OnInit {

    constructor(private loginService:UserloginService) { }

   

    billingAddressObj:any;
    shippingAddressObj:any;
    ngOnInit(): void {
      let token = localStorage.getItem('token');
      if(token==null || token=='null'){
        Swal.fire({
            title: 'Please log into the system!',
          }).then((result) => {
            
          })
      }else{
        let details = {
            'token':token,
          }
          const uploadFormData = new FormData();
          uploadFormData.append('details', JSON.stringify(details));
         this.loginService.getUserAddresses(uploadFormData).subscribe(res=>{
            this.billingAddressObj = res.billingAddress;
            this.shippingAddressObj = res.shippingAddress;
        })
      }
    }

}