import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { SubCategory } from '../../model/sub_category';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-sub-category',
  templateUrl: './edit-sub-category.component.html',
  styleUrl: './edit-sub-category.component.scss'
})
export class EditSubCategoryComponent {

  id: number = 0;
  data: any;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.maserfilesService.getDetails('subcategory', this.id).subscribe(res => {
        this.data = res.data;
        this.selectedCategory = this.data.category_id;
        console.log("sub category details " + JSON.stringify(res));

      })
    })
  }

  categoryList:Array<any>=[];
  constructor(private maserfilesService: MaserfilesService,private route: ActivatedRoute) {
    maserfilesService.getAll('category').subscribe(res=>{
      this.categoryList = res;
      console.log("main categories "+JSON.stringify(res));
    })
   }
  subCategory!: SubCategory;
  selectedCategory:number=0;
  submit(form) {
    var subcategory = form.subcategory;
    console.log(subcategory);
  
    this.subCategory = new SubCategory( this.id, this.selectedCategory, subcategory, '', '');
    this.maserfilesService.update(this.subCategory, 'subcategory').subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Success!',
        }).then((result) => {
          location.reload();
        })
      } else {

        Swal.fire({
          title: res.message,
        }).then((result) => {
          //location.reload();
        })

      }
    })
  }

  selectCategory(id:any){
    this.selectedCategory = id;
  }
}
