<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    
</div>
<!-- End Page Title Area -->

<!-- Start Checkout Area -->
<section class="checkout-area" style="padding-top: 20px;">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="billing-details">
                            <h3 class="title" *ngIf="billingAddressObjSize==1">Billing Address</h3>
                            <div class="row" *ngIf="billingAddressObjSize==0">
                                <div class="col-lg-6 col-md-12">
                                    <h3 class="title">Billing Address</h3>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <a routerLink="/add_address/Billing"  class="default-btn"><i class='bx bx-plus'></i>ADD Billing Address</a>
                                   
                                </div>
                            </div>
                            
                            <div class="row" *ngFor="let bilingAddress of billingAddressObj">
                                <p>{{bilingAddress.line1}},<br>{{bilingAddress.line2}}, <br>{{bilingAddress.line3}}.<br>{{bilingAddress.postalCode}}<br>{{bilingAddress.country}}<br></p>
                                <a routerLink="/edit_address/billing/{{bilingAddress.id}}"> <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6></a>
                                <br><br>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="billing-details">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3 class="title">Shipping Address</h3>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <a routerLink="/add_address/Shipping"  class="default-btn" style="background-color: rgb(67, 67, 238);"><i class='bx bx-plus'></i>ADD Shipping Address</a>
                                   
                                </div>
                            </div>
                            <div class="row" *ngFor="let shippingAddress of shippingAddressObj">
                                <div class="col" (click)="selectedShippingAddres(shippingAddress)" >
                                    <span><input type="checkbox"> <b style="padding-left: 5px;">Select Address</b></span>
                                <p>{{shippingAddress.line1}}, {{shippingAddress.line2}}, {{shippingAddress.line3}}, {{shippingAddress.country}}. {{shippingAddress.postalCode}}</p>
                              
                               <a routerLink="/edit_address/shipping/{{shippingAddress.id}}"> <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6></a>
                                <br>
                                </div>
                                
                                
                            </div>  
                        </div>
                         <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Order Notes</label>
                                        <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                    </div>
                                </div>
                    </div> 
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr *ngFor="let cart of userCartDetailsList">
                                        <td class="product-name"><a routerLink="/simple-product">	{{cart.title}}</a></td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{cart.totoalPrice}}</span>
                                        </td>
                                    </tr>
                                   
                                   
                                    <tr>
                                        <td class="order-subtotal"><span>Cart Subtotal</span></td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">{{subTotalData}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-shipping"><span>Shipping</span></td>
                                        <td class="shipping-price">
                                            <span>{{shipping}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="total-price"><span><b>Total</b></span></td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount"><b>LKR {{allTotal_value}}</b></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                
                                <p (click)="handleClick('paypal')">
                                    <input type="radio" id="paypal" name="radio-group">
                                    <label for="paypal">Card Pay</label>
                                    <img src="assets/img/card_pay.png">
                                </p>
                                <p (click)="handleClick('genie')">
                                    <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Genie Pay</label>
                                    <img src="assets/img/genie_pay.png">
                                </p>
                            </div>
                            <button type="submit" class="default-btn" (click)="openGatway()"><i class='bx bx-paper-plane'></i> PLACE ORDER</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- End Checkout Area -->

<!--div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div-->