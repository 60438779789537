
<app-navbar-style-two></app-navbar-style-two>


<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});height: 200px;">
    <h1 style="color: white;">{{Content.title}}</h1>
   
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h3>Contact Information</h3>
                    <p>Zonealarm Mobile Security is the ultimate antivirus security solution for your mobile device. Developed by Cyber Security leader Check Point, it uses enterprise-grade technology to protect your data and privacy and keep your device free from malicious threats, apps, and malware.</p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h3>Got Any Questions?</h3>
                    <p>Use the form below to get in touch with the team.</p>
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6  col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel minlength="10" maxlength="12" name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">
                                            <div *ngIf="number.errors.required">Phone number is required.</div>
                                            <div *ngIf="number.errors.minlength">Phone number is minimum {{ number.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="number.errors.maxlength">Phone number is maximum 12 character.</div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="col-lg-6  col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="Subject">
                                        <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Subject is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SEND MESSAGE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<br><br>
<br><br><br>
