import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.scss'
})
export class UserLoginComponent {
  constructor(private userlogin:UserloginService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Profile Authentication'
        }
    ]

    lostPasswordCliked:boolean=false;
    lostPassword(){
        if(!this.lostPasswordCliked){
            this.lostPasswordCliked = true;
        }else{
            this.lostPasswordCliked = false;
        }
        
    }

    submitLostPassword(form){
        var email = form.email;
        console.log(email);
        let details = {
            'email':email,
    
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.forgotPassword(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Password reset mail has been sent your email address.!',
                  }).then((result) => {
                   
                  })
            }else{
             Swal.fire({
                 title: 'Invalid Email',
            }).then((result) => {
                      
            })
                
            }
        })
    }


    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        let details = {
            'email':email,
            'password':password,  
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.loginUser(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                    var token = res.token;
                    localStorage.setItem('token',token);
                    localStorage.setItem('accShow','show');
                    window.location.href = "/";
                  })
            }else{
                if(status == 'invalidPassword'){
                    Swal.fire({
                        title: 'Invalid Password',
                      }).then((result) => {
                        location.reload();
                      })
                }else{
                    Swal.fire({
                        title: 'Invalid User',
                      }).then((result) => {
                        location.reload();
                      })
                }
            }
        })
    }

}
