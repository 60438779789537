<div class="modal fade productsQuickView2" id="productsQuickView2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                        <button type="button" class="close2" data-bs-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true"><i class='bx bx-x' style="font-size:28px;"></i></span>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="loginFormDisplay">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;">Login</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                                <div class="form-group">
                                    <label>Enter Email</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>
                
                                
                                <button type="submit"  (click)="loginToSystem(email.value,password.value)">LOGIN</button>
                            </form>
                            <br>
                            <h6 style="text-align: center;color: rgb(3, 3, 52);">OR</h6>
                            
                            <h6 style="text-align: center;color: rgb(3, 3, 52);padding-bottom: 20px;">
                                <button class="tiltle" (click)="registerClicked()"> Create an Account</button>
                            </h6>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!loginFormDisplay">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;">Register</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                                <!--div class="form-group">
                                    <label>Enter User Name *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter User Name">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Mobile Number *</label>
                                    <input required minlength="3" maxlength="12" ngModel name="mobile" type="text" #mobile="ngModel" class="form-control" id="mobile" placeholder="Enter Mobile Number">
                                </div-->
                                <div class="form-group" >
                                    <label>Enter Email *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="otp" placeholder="Enter Email">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Password *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>

                                <button type="submit" (click)="registerUser(email.value,password.value)" *ngIf="!registerButtonClicked">REGISTER</button>
                                <div  *ngIf="registerButtonClicked">
                                    <div class="form-group">
                                        <label>Enter Email Verification Code *</label>
                                        <input required minlength="3" maxlength="50" ngModel name="emailverification" type="text" #emailverification="ngModel" class="form-control" id="emailverification" placeholder="Enter Email Verification Code">
                                    </div>
    
                                    <button type="submit" (click)="verifyAccount(emailverification.value)" >VERIFY</button>

                                </div>
                               
                                <!--div class="form-group" *ngIf="registerButtonClicked">
                                    <label>Enter OTP *</label>
                                    <input required minlength="3" maxlength="12" ngModel name="otp" type="text" #otp="ngModel" class="form-control" id="otp" placeholder="Enter OTP">
                                </div>
                                <div class="form-group" *ngIf="registerButtonClicked">
                                    <label>Enter Email Verification Code *</label>
                                    <input required minlength="3" maxlength="50" ngModel name="emailverification" type="text" #emailverification="ngModel" class="form-control" id="emailverification" placeholder="Enter Email Verification Code">
                                </div>

                                <button type="submit" (click)="verifyAccount(otp.value,emailverification.value)" *ngIf="registerButtonClicked">VERIFY</button>
                            -->
                               
                            </form>
                            <br>
                            <h6 style="text-align: center;color: rgb(3, 3, 52);">OR</h6>
                            
                            <h6 style="text-align: center;color: rgb(3, 3, 52);padding-bottom: 20px;">
                                <button class="tiltle" (click)="registerClicked()">Login</button>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
