import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from './backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  table='sales';
  getAll(status:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'admin/'+this.table+'/getall?status='+status);
   
  }

  updateInvoiceStatus(status:any,order_id:any,track_number:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'admin/'+this.table+'/updateinvoicestatus?status='+status+'&order_id='+order_id+'&track_number='+track_number);
   
  }
}
