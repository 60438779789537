import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoGalleryService } from '../../common_service/video-gallery.service';

@Component({
    selector: 'app-coming-soon-page',
    templateUrl: './coming-soon-page.component.html',
    styleUrls: ['./coming-soon-page.component.scss']
})
export class ComingSoonPageComponent implements OnInit {
    videoList:Array<any>=[];
    constructor(private videoGalleryService :VideoGalleryService) {
        videoGalleryService.getVideosGallery().subscribe(res=>{
            this.videoList = res;
        })
     }

    pageTitle = [
        {
            bgImage: 'https://tedismart.com/assets/images/page-header-bg.jpg',
            title: 'Video Gallery'
        }
    ]

    name = 'Video events';
    videoSource = "https://tedismart.com/videos/Air_Fryer.mp4";

    @ViewChild('videoPlayer') videoplayer: any;
    public startedPlay: boolean = false;
    public show: boolean = false;

    openVideo(url:any){
        this.videoSource=url;
        console.log("url "+url);
        if (this.videoplayer && this.videoplayer.nativeElement) {
            this.videoplayer.nativeElement.load();
            this.videoplayer.nativeElement.play();
        }
    }

    pauseVideo(videoplayer) {
        videoplayer.nativeElement.play();

        setTimeout(() => {
            videoplayer.nativeElement.pause();
            if (videoplayer.nativeElement.paused) {
                this.show = !this.show;
            }
        }, 5000);

    }

    closebutton(videoplayer){
        this.show = !this.show; 
        videoplayer.nativeElement.play();
      }
      

    ngOnInit() {

    }



}