import { Component, OnInit } from '@angular/core';
import { GuardAdmin, GuardSuperAdmin } from '../../admin_service/guard.admin';

@Component({
    selector: 'app-navbar-style-three',
    templateUrl: './navbar-style-three.component.html',
    styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent {
  logOutClicked(){
    localStorage.setItem("token","empty");
    localStorage.setItem("accShow","notShow");
    window.location.href="/";
  }

}