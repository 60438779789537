
<app-navbar-style-two></app-navbar-style-two>

<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 " style="color: rgba(2, 85, 112);">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_categories">
                        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                            id="menu">
                            <li class="nav-item" >
                                <a class="nav-link align-middle px-0" (click)="showThis('Orders')"
                                    *ngIf="type!='Orders'">
                                    <i class="bx bx-purchase-tag" style="font-size: 18px;color: black;"></i> <span
                                        class="ms-1 d-none d-sm-inline"
                                        style="font-size: 18px;color: black;">Orders</span>

                                </a>
                                <a class="nav-link align-middle px-0" (click)="showThis('Orders')"
                                    *ngIf="type=='Orders'">
                                    <i class="bx bx-purchase-tag" style="font-size: 20px;color: rgba(2, 85, 112);"></i>
                                    <span class="ms-1 d-none d-sm-inline"
                                        style="font-size: 20px;color: rgba(2, 85, 112);">Orders</span>

                                </a>
                            </li>
                            <li class="nav-item" >
                                <a class="nav-link align-middle px-0" (click)="showThis('Profile')"
                                    *ngIf="type!='Profile'">
                                    <i class="bx bx-user" style="font-size: 18px;color:  black"></i> <span
                                        class="ms-1 d-none d-sm-inline"
                                        style="font-size: 18px;color:  black;">Profile</span>
                                </a>
                                <a class="nav-link align-middle px-0" (click)="showThis('Profile')"
                                    *ngIf="type=='Profile'">
                                    <i class="bx bx-user" style="font-size: 20px;color:  rgba(2, 85, 112);"></i> <span
                                        class="ms-1 d-none d-sm-inline"
                                        style="font-size: 20px;color:  rgba(2, 85, 112);">Profile</span>
                                </a>
                            </li>
                            <li class="nav-item" >
                                <!--a class="nav-link align-middle px-0" (click)="showThis('Address')"
                                    *ngIf="type!='Address'">
                                    <i class="bx bx-home" style="font-size: 18px;color: black;"></i> <span
                                        class="ms-1 d-none d-sm-inline"
                                        style="font-size: 18px;color:black;">Address</span>
                                </a-->
                                <a class="nav-link align-middle px-0" (click)="showThis('Address')"
                                    *ngIf="type=='Address'">
                                    <i class="bx bx-home" style="font-size: 20px;color: rgba(2, 85, 112);"></i> <span
                                        class="ms-1 d-none d-sm-inline"
                                        style="font-size: 20px;color:rgba(2, 85, 112);">Address</span>
                                </a>
                            </li>

                            <li class="nav-item"
                               >
                                <a class="nav-link align-middle px-0" (click)="showThis('LogOut')"
                                    *ngIf="type!='LogOut'">
                                    <i class="bx bx-log-out" style="font-size: 18px;color:  black;"></i> <span
                                        class="ms-1 d-none d-sm-inline" style="font-size: 18px;color:  black;">Log
                                        Out</span>
                                </a>
                                <a class="nav-link align-middle px-0" (click)="showThis('LogOut')"
                                    *ngIf="type=='LogOut'">
                                    <i class="bx bx-log-out" style="font-size: 20px;color:  rgba(2, 85, 112);"></i>
                                    <span class="ms-1 d-none d-sm-inline"
                                        style="font-size: 20px;color:  rgba(2, 85, 112);">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
        <div class="col py-3">
            <div *ngIf="type=='Orders'">
                <div class="d-flex align-items-center" *ngIf="preOrderListSize==0">
                    <p>No Orders Found.</p>
                </div>
                <div class="order_details" *ngFor="let order of preOrderList">
                    <div class="row" style="border: 1px solid rgba(2, 85, 112);margin-bottom: 10px;">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <td style="color:rgba(2, 85, 112);">Order ID </td>
                                    <td style="color:rgba(2, 85, 112);">: {{order.order_id}}</td>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <td>Date </td>
                                    <td>: {{order.invoiceCreatedDate}} {{order.invoiceCreatedTime}}</td>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">

                                </div>
                                <div class="col-4">
                                    <b>Product Title</b>
                                </div>
                                <div class="col-4">
                                    <b>Quantity</b>
                                </div>
                            </div>
                            <div class="row" *ngFor="let orderItems of order.invoiceItems">
                                <div class="col-4">
                                    <img [src]="orderItems.mainImg" alt="image" style="height: 100px;">
                                </div>
                                <div class="col-4">
                                    {{orderItems.title}}
                                </div>
                                <div class="col-4">
                                    {{orderItems.quantity}}
                                </div>

                            </div>
                            <!--table style="border: 1px solid rgb(161, 220, 53);">
                                <th></th>
                                <th>Product Title</th>
                                <th>Quantity</th>
                                <tr *ngFor="let orderItems of order.invoiceItems">
                                    <td><img [src]="orderItems.mainImg" alt="image" style="height: 100px;"></td>
                                    <td>{{orderItems.title}}</td>
                                    <td>{{orderItems.quantity}}</td>
                                </tr>
                            </table-->
                        </div>
                        <br><br>
                    </div>



                </div>
            </div>
            <div class="login-form" *ngIf="type=='Profile'">
                <h2 style="text-align: center;">Edit Profile</h2>
                <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                    <div class="form-group">
                        <label>Username</label>
                        <input required minlength="3" maxlength="50" ngModel [(ngModel)]="userName" name="username"
                            type="text" #username="ngModel" class="form-control" id="username"
                            placeholder="Enter Username">
                    </div>

                    <div class="form-group">
                        <label>Email</label>
                        <input required minlength="3" maxlength="50" ngModel [(ngModel)]="emailval" name="email"
                            type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email"
                            readonly>
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input required minlength="3" maxlength="50" ngModel name="password" type="password"
                            #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                    </div>

                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input required minlength="3" maxlength="50" ngModel name="confirmpassword" type="password"
                            #confirmpassword="ngModel" class="form-control" id="confirmpassword"
                            placeholder="Confirm Password">
                    </div>
                    <button type="submit">UPDATE PROFILE</button>
                </form>
            </div>
            <div class="row" *ngIf="type=='Address'">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <h3 class="title">Billing Address</h3>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <a routerLink="/add_address/Billing" class="default-btn"><i class='bx bx-plus'></i>ADD
                                    Billing Address</a>

                            </div>
                        </div>

                        <div class="row" *ngFor="let bilingAddress of billingAddressObj">
                            <p>{{bilingAddress.line1}},<br>{{bilingAddress.line2}},
                                <br>{{bilingAddress.line3}}.<br>{{bilingAddress.postalCode}}<br>{{bilingAddress.country}}<br>
                            </p>
                            <a routerLink="/edit_address/billing/{{bilingAddress.id}}">
                                <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6>
                            </a>
                            <br><br>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <h3 class="title">Shipping Address</h3>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <a routerLink="/add_address/Shipping" class="default-btn"
                                    style="background-color: rgb(67, 67, 238);"><i class='bx bx-plus'></i>ADD Shipping
                                    Address</a>
                            </div>
                        </div>
                        <div class="row" *ngFor="let shippingAddress of shippingAddressObj">
                            <div class="col">
                                <span><input type="checkbox"> <b style="padding-left: 5px;">Select Address</b></span>
                                <p>{{shippingAddress.line1}}, {{shippingAddress.line2}}, {{shippingAddress.line3}},
                                    {{shippingAddress.country}}. {{shippingAddress.postalCode}}</p>

                                <a routerLink="/edit_address/shipping/{{shippingAddress.id}}">
                                    <h6 style="color: rgba(2, 85, 112, 1);">Edit <i class='bx bx-edit'></i></h6>
                                </a>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>