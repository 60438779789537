export class SubCategory {
    id: number;
    category: number;
    subcategory: string;
    created_at: string;
    updated_at: string;
   

    constructor(
        id: number,
        category: number,
        subcategory:string,
        created_at:string,
        updated_at:string

    ) {
        this.id = id;
        this.category = category;
        this.subcategory = subcategory;
        this.created_at = created_at;
        this.updated_at = updated_at;
       
       
    }

    getId(): number {
        return this.id;
    }
    setId(id: number): void {
        this.id = id;
    }
    getCategory(): number {
        return this.category;
    }
    setCategory(category: number): void {
        this.category = category;
    }
    getCreatedAt(): string {
        return this.created_at;
    }
    setCreatedAt(created_at: string): void {
        this.created_at = created_at;
    }

    getUpdated_at(): string {
        return this.updated_at;
    }
    setUpdated_at(updated_at: string): void {
        this.updated_at = updated_at;
    }
  
    getSubcategory(): string {
        return this.subcategory;
    }
    setSubcategory(subcategory: string): void {
        this.subcategory = subcategory;
    }
    


}
