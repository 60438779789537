<app-navbar-style-three></app-navbar-style-three>

<div class="section-title">
    <h2>Edit Product Details</h2>

</div>


<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Title</label>
                                        <input required minlength="3" maxlength="50" ngModel name="productTitle"
                                            [ngModel]="data.title" type="text" #productTitle="ngModel"
                                            class="form-control" id="productTitle" placeholder="Product Title">

                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Category</label>
                                        <select #category class="form-select" [(ngModel)]="selectedCategory"
                                            (change)="selectCategory(category.value)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option>Select Category</option>
                                            <option *ngFor="let category of mainCategoriesList" value="{{category.id}}">
                                                {{category.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Sub Category</label>
                                        <select #subCategory class="form-select" [(ngModel)]="selectedSubCategory"
                                            (change)="selectSubCategory(subCategory.value)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option>Select Sub Category</option>
                                            <option *ngFor="let category of subCategoriesList" value="{{category.id}}">
                                                {{category.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Product Company</label>
                                        <input required ngModel name="productCompany" type="text"
                                            [ngModel]="this.data.product_company" #productCompany="ngModel"
                                            class="form-control" id="productCompany" placeholder="Product Company">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Product Price Before Discount (LKR)</label>
                                        <input required ngModel name="productPrice" type="text"
                                            [ngModel]="data.product_price_before_discount" #productPrice="ngModel"
                                            class="form-control" id="productPrice" placeholder="Product Price">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Price Value (Ex:11300.50) </label>
                                        <input required ngModel name="productPriceValue" type="text"
                                            [ngModel]="this.data.product_price" #productPriceValue="ngModel"
                                            class="form-control" id="productPriceValue"
                                            placeholder="Product Price - 11300.50">
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>Simple Description</label>
                                        <input required ngModel name="simpleDesciption" type="text"
                                            [ngModel]="this.data.simple_description" #simpleDesciption="ngModel"
                                            class="form-control" id="simpleDesciption" placeholder="Simple Desciption">

                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>Product Description
                                        </label>
                                        <input required ngModel name="productDesciption" type="text"
                                            [ngModel]="this.data.product_description" #productDesciption="ngModel"
                                            class="form-control" id="productDesciption"
                                            placeholder="Product Desciption">

                                    </div>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Product Shipping Charge (LKR)</label>
                                        <input required ngModel name="shippingCharge" type="text"
                                            [ngModel]="this.data.shipping_charge" #shippingCharge="ngModel"
                                            class="form-control" id="shippingCharge" placeholder="900.00">
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Product Availability</label>
                                        <select #productAv class="form-select" [ngModel]="product_availability"
                                            (change)="selectProductAvailability(productAv.value)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let availability of productAvailabilityList"
                                                value="{{availability.value}}">
                                                {{availability.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Warrenty</label>
                                        <select #productWarrenty class="form-select" [ngModel]="warrenty"
                                            (change)="selectWarrenty(productWarrenty.value)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let warranty of warrentyList" value="{{warranty.value}}">
                                                {{warranty.value}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Sale Type</label>
                                        <select #sale class="form-select" [ngModel]="sale_type"
                                            (change)="selectSaleType(sale.value)" [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let salTy of saleTypesList" value="{{salTy.value}}">
                                                {{salTy.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Available Stock
                                        </label>
                                        <input required ngModel name="availableStock" type="text"
                                            [ngModel]="this.data.stock_available" #availableStock="ngModel"
                                            class="form-control" id="availableStock" placeholder="10">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Height (cm)
                                        </label>
                                        <input required ngModel name="height" type="text" [ngModel]="this.data.height"
                                            #height="ngModel" class="form-control" id="height" placeholder="10">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Width (cm)
                                        </label>
                                        <input required ngModel name="width" type="text" [ngModel]="this.data.width"
                                            #width="ngModel" class="form-control" id="width" placeholder="10">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Length (cm)
                                        </label>
                                        <input required ngModel name="length" type="text" [ngModel]="this.data.length"
                                            #length="ngModel" class="form-control" id="length" placeholder="10">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Volumetric Weight (gm)
                                        </label>
                                        <input required ngModel name="volWeight" type="text"
                                            [ngModel]="this.data.volumetric_weight" #volWeight="ngModel"
                                            class="form-control" id="volWeight" placeholder="10">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label>Weight (gm)
                                        </label>
                                        <input required ngModel name="weight" type="text" [ngModel]="this.data.weight"
                                            #weight="ngModel" class="form-control" id="weight" placeholder="10">
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">


                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <label>Add Type (Ex:Colors, Gang )</label>
                                        <input required ngModel name="productOtherTypes" type="text"
                                            [(ngModel)]="productOtherTypes" class="form-control" id="productOtherTypes"
                                            placeholder="Colors">
                                    </div>
                                    <ul *ngFor="let Content of addOtherList;" class="reduce-space">
                                        <li [innerHTML]="Content">{{Content}} </li>
                                    </ul>
                                    <div class="form-group mb-3" *ngIf="addOtherList.length>0">
                                        <button class="default-btn" (click)="doneAdding()">Add Type Details</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Add Value</label>
                                        <input required ngModel name="productOtherTypesValue" type="text"
                                            [(ngModel)]="productOtherTypesValue" class="form-control"
                                            id="productOtherTypesValue" placeholder="White">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <button class="default-btn"
                                            (click)="addOtherDetails(productOtherTypes,productOtherTypesValue)"><i
                                                class='bx bx-plus'></i>Add</button>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let highlight of doneOtherDetialsList;">
                                            <tr>
                                                <td>{{highlight.type}}</td>
                                                <td *ngFor="let typeVal of highlight.values;">
                                                    <p>{{typeVal}}</p>
                                                </td>

                                                <td><button (click)="deleteOtherTypes(highlight)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <p><b>Add Product Information</b></p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="productInformation" type="text"
                                            [(ngModel)]="productInformation" class="form-control"
                                            id="productInformation" placeholder="Add Product Information">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addToProductInformationToList()"><i
                                                class='bx bx-plus'></i>Add To List</button>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let proInfo of productInformationList;">
                                            <tr>
                                                <td>{{proInfo}}</td>
                                                <td><button (click)="deleteProductInfoInList(proInfo)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <!--ul *ngFor="let Content of productHighlightList;" >
                                        <li [innerHTML]="Content">{{Content}}  </li>
                                    </ul-->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <p><b>Add Additional Informations</b></p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="additionInfoType" type="text"
                                            [(ngModel)]="additionInfoType" class="form-control" id="additionInfoType"
                                            placeholder="Add Type">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="additionInfoValue" type="text"
                                            [(ngModel)]="additionInfoValue" class="form-control" id="additionInfoValue"
                                            placeholder="Add Value">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addSpecification()"><i
                                                class='bx bx-plus'></i>Add Specification</button>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody *ngFor="let info of productAdditonalInfoList;">
                                            <tr>
                                                <td>{{info.type}}</td>
                                                <td>{{info.value}}</td>
                                                <td><button (click)="deleteAdditionalInfo(info)"><i
                                                            class='bx bx-trash'></i></button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" (click)="submit(contactForm.value)" class="default-btn"
                                        [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Update
                                        Product</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>



<div class="section-title">
    <h2>Edit Product Images</h2>

</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">

                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <label>Edit Main Image</label>
                                        <img [src]="this.data.mainImg">
                                        <br>

                                    </div>

                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group mb-3">
                                        <input type="file" class="file-input" #fileUploadVehicleReg required
                                            (change)="onFileSelectedMainImg($event)">
                                        
                                        <button class="btn btn-primary" (click)="saveMainImage(fileUploadVehicleReg)">
                                            Update Main Image</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>Edit Other Images</label>
                                        <br>
                                        <div>
                                            <div class="row" *ngFor="let img of otherImageList">
                                                <div class="col-lg-3 col-md-3">
                                                    <img [src]="img.otherImgUrl">
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <input type="file" class="file-input" #fileuploadotherimages
                                                        required (change)="onFileSelectedOtherImages($event)">
                                                </div>
                                                <div class="col-lg-3 col-md-3">
                                                    <div class="form-group mb-3">
                                                        <button class="btn btn-primary"
                                                            (click)="addImagesOtherToList(fileuploadotherimages,img.imageName)">
                                                            Update Image </button>
                                                    </div>

                                                </div>
                                                <div class="col-lg-2 col-md-2">
                                                    <div class="form-group mb-3">
                                                        <button class="btn btn-warning"
                                                            (click)="deleteImage(img.imageName)">
                                                            Delete </button>
                                                    </div>

                                                </div>
                                                <br>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Upload More Image</label>
                                        <br>
                                        <input type="file" class="file-input" #fileuploadotherimages required
                                            (change)="onFileSelectedOtherImages($event)">
                                        <button class="btn btn-primary"
                                            (click)="addImagesOtherToList(fileuploadotherimages,'')">
                                            Add Image </button>
                                    </div>
                                </div>

                            </div>


                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>