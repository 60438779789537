import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {

    constructor(private userlogin:UserloginService,private route: ActivatedRoute) { }

    addressId:any;
    addressDetails:any;
    type:any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.addressId = params['id']; 
            this.type = params['type'];
            const uploadFormData = new FormData();
            let token = localStorage.getItem('token');
            let details = {
                'id':this.addressId,
                'token':token
            }
          uploadFormData.append('details', JSON.stringify(details));
            this.userlogin.getAddressById(uploadFormData).subscribe(res=>{
                this.addressDetails = res.data;
               this.type = this.addressDetails.type;
                console.log("address data "+JSON.stringify(this.addressDetails ) )
            })
        })
    }

    submit(form){
        var line1 = form.line1;
        var line2 = form.line2;
        var line3 = form.line3;
        var city = form.city;
        var postalCode = form.postalCode;
        var country = form.country;

        let address = {
            'line1':line1,
            'line2':line2,  
            'line3':line3,
            'city':city,
            'postalCode':postalCode,
            'country':country,
            'type':this.type,
            'id':this.addressId
        }

        let token = localStorage.getItem('token');
        let details = {
            'address':address,
            'token':token,
            'id':this.addressId
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.updateAddress(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                   location.reload();
                  })
            }else{
                if(status == 'Invalid_user'){
                    Swal.fire({
                        title: 'Invalid User',
                      }).then((result) => {
                        
                      })
                }
                else{
                    Swal.fire({
                        title: 'Error',
                      }).then((result) => {
                       
                      })
                }
            }
        })
    }

}