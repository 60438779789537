<!--app-navbar-style-one></app-navbar-style-one-->
<app-navbar-style-two></app-navbar-style-two>



<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            <div div class="col-lg-3 col-md-2 col-sm-2">
            </div>
            <div class="col-lg-6 col-md-8 col-sm-8">
                <div class="login-form">
                    <h2 style="text-align: center;">Reset Password</h2>
        
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>New Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="newPassword" type="password" #newPassword="ngModel" class="form-control" id="newPassword" placeholder="Enter New Email">
                        </div>
        
                        <div class="form-group">
                            <label>Confirm New Password</label>
                            <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Password">
                        </div>
        
                        <button type="submit">RESET</button>
                    </form>
                </div>
            </div>
           
            <div div class="col-lg-3 col-md-2 col-sm-2">
                
            </div>
        </div>
    
    </div>
</section>