import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../common_service/product.service';
import Swal from 'sweetalert2';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';
import { DialogService } from '../../common_service/dialog.service';
import { VerificationModel } from '../../model/VerificationModel';
import { ProductServiceService } from '../../admin_service/product-service.service';
import { ProductPriceModel } from '../../model/ProductPricesMode';

@Component({
    selector: 'app-new-products',
    templateUrl: './new-products.component.html',
    styleUrls: ['./new-products.component.scss']
})
export class NewProductsComponent implements OnInit {
    singleProductsItem:Array<any> =[];
    productPriceModel!:ProductPriceModel;
    token=localStorage.getItem('token');
    accShow=localStorage.getItem('accShow');
    constructor(private addToWishListService:AddToWishListService,
        private dialogService:DialogService,private productService:ProductServiceService
    ) {
    //  localStorage.setItem('token','empty');
         this.token = localStorage.getItem('token');
         this.accShow = localStorage.getItem('accShow');
         localStorage.setItem('package',"NO");
      
    
         this.productService.getProductsPricesInHome().subscribe(res=>{
          this.productPriceModel = res.data;
         })
     }

     checkExpiration(type:any){
        if( this.token!=null && this.accShow=='show'){
            this.dialogService.checkTokenExpiration(null).subscribe(res=>{
              if(res.status=='success'){
                localStorage.setItem('token','empty');
              localStorage.setItem('accShow','notShow');
              Swal.fire({
                title: res.message,
              }).then((result) => {
                location.reload();
              })
              }else{
                window.location.href="selectpackage/"+type;
              }
              
            })
          }
     }

     //******************** Select Package ************** */
     verificationModel!:VerificationModel;
     selectPackege(type:any){
      localStorage.setItem('package',type);
       
     }

   

     //********************* Select Package End **************** */


    

     addToWishList(productId:any){
        let token = localStorage.getItem('token');
        console.log("token "+token);
        if(token==null){
            Swal.fire({
                title: 'You need to log in before you add an item!',
              }).then((result) => {
                window.location.href = "/login";
              })
            
        }else{
            let details = {
                'token':token,
                'productId':productId
              }
            const uploadFormData = new FormData();
            uploadFormData.append('details', JSON.stringify(details));
            this.addToWishListService.addToWishList(uploadFormData).subscribe(res=>{
                let status = res.status;
                if(status=="success"){
                    Swal.fire({
                        title: 'Item successfully added to the wish list!',
                      }).then((result) => {
                        
                      })  
                }else{
                    if(status=='AlreadyIn'){
                        Swal.fire({
                            title: 'This product already is in the wish list!',
                          }).then((result) => {
                            
                          }) 
                    }else{
                        Swal.fire({
                            title: 'There is an error with adding to the wish list!',
                          }).then((result) => {
                            
                          })  
                    }  
                }
            
            })

        }
        

     }
    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Welcome to ZoneAlarm Subscription",
            paragraph: "Secure your mobile life anytime, anywhere."
        }
    ]
    
    //***************Table ****************** */
    rows = [];
    loading = false;
    actionsTemplate: any;
    cols = [
      { prop: 'id', name: 'ID' },
      { prop: 'title', name: 'Title' },
      { prop: 'description', name: 'Description' },
      { prop: 'createdDateTime', name: 'Created At' },
      //{ prop: 'updated', name: 'Last Updated At' },
      { prop: 'actions', name: 'Actions' }
    ];

}