import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LoginServiceService } from 'src/app/components/admin_service/login-service.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-bedroom-products',
    templateUrl: './bedroom-products.component.html',
    styleUrls: ['./bedroom-products.component.scss']
})
export class BedroomProductsComponent implements OnInit {
  ngOnInit(): void {
    localStorage.setItem('token',"");
  }
    constructor(private adminLogin:LoginServiceService) { }

    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        let details = {
            'email':email,
            'password':password,  
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.adminLogin.loginAdmin(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                    var token = res.token;
                    localStorage.setItem('token',token);
                    localStorage.setItem('userType','ADMIN');
                    window.location.href = "/admin/home";
                  })
            }else{
                if(status == 'invalidPassword'){
                    Swal.fire({
                        title: 'Invalid Password',
                      }).then((result) => {
                      //  location.reload();
                      })
                }else{
                    Swal.fire({
                        title: 'Invalid User',
                      }).then((result) => {
                        //location.reload();
                      })
                }
            }
        })
    }

}