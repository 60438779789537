import { Component } from '@angular/core';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-main-category',
  templateUrl: './view-main-category.component.html',
  styleUrl: './view-main-category.component.scss'
})
export class ViewMainCategoryComponent {
  constructor(private maserfilesService: MaserfilesService, private route: ActivatedRoute) { }
  
  id: number = 0;
  data: any;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.maserfilesService.getDetails('category', this.id).subscribe(res => {
        this.data = res.data;
        console.log("category details " + JSON.stringify(res));

      })
    })
  }
}
