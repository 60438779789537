import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { customerModel } from '../../model/customerModel';
import { VerificationModel } from '../../model/VerificationModel';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrl: './customer-login.component.scss'
})
export class CustomerLoginComponent {
  token:any;
  accShow:any;
    constructor(private userlogin:UserloginService) {
      this.token = localStorage.getItem('token');
      this.accShow = localStorage.getItem('accShow');
      console.log("token "+this.token);
      console.log("accShow "+this.accShow);
     }

   //*************************Login Start *******************************/
  
   ngOnInit(): void {
  }

  pageTitle = [
      {
          bgImage: 'assets/img/page-title-bg.jpg',
          title: 'Profile Authentication'
      }
  ]

  otpRequested:boolean=false;
  loginFormDisplay:boolean=true;

 /* requestOTp(mobile:any){
    if(mobile==''){
      Swal.fire({
        title: 'Please enter mobile number!',
      }).then((result) => {
        
      })
    }else{
      let details = {
        'mobile':mobile,
    }
    this.verificationModel = new VerificationModel(mobile,0,"");
    this.userlogin.requestOtp(this.verificationModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })
        
    })
    }
  }*/

  loginToSystem(email:any,password:any){
    if(email==''){
      Swal.fire({
        title: 'Please enter Email!',
      }).then((result) => {
        
      })
    }else if(password==''){

      Swal.fire({
        title: 'Please enter Password!',
      }).then((result) => {
        
      })
    }else{
    this.customerModel = new customerModel(0,email,password,"","");
    
    this.userlogin.loginUser( this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          let token = res.data;
          localStorage.setItem('token',token);
          localStorage.setItem('type','customer');
          localStorage.setItem('accShow','show');
          
          location.reload();
        }
        
    })
    }
  }

  registerClicked(){
    this.loginFormDisplay=!this.loginFormDisplay;
  }

  customerModel!:customerModel;
  verificationModel!:VerificationModel;
  registerButtonClicked:boolean=false;
  customerRegisteredEmail:any;
  registerUser(email:any,password:any){
    if(email==''){
      Swal.fire({
        title:'Please enter email!',
      }).then((result) => {
       
      })
    }else if(password==''){
      Swal.fire({
        title:'Please enter password!',
      }).then((result) => {
       
      })
    }else{
     
    this.customerModel = new customerModel(0,email,password,"","");
    this.userlogin.registerUser(this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          this.customerRegisteredEmail = email;
          this.registerButtonClicked = true;
        }
        
    })
    }
  }

  verifyAccount(emailcode:any){
     if(emailcode==''){
      Swal.fire({
        title:'Please enter email verification code!',
      }).then((result) => {
       
      })
    }else{
    this.verificationModel = new VerificationModel( this.customerRegisteredEmail,emailcode,'',0);
    this.userlogin.verification(this.verificationModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
          
        })

        if(status=='success'){
          this.registerClicked();
        }

        
        
    })
    }
  }

}
