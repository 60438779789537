
<app-navbar-style-one></app-navbar-style-one>
<section class="profile-authentication-area ptb-20">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="login-form">
                    <h2 style="text-align: center;">Login</h2>
        
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>
        
                        <div class="form-group">
                            <label>Password</label>
                            <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>
        
                        
        
                        <button type="submit">LOG IN</button>
                    </form>
                    <br>
                    

                  
                </div>
            </div>
            
           
        </div>
    
    </div>
</section>

