import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { SupportService } from '../../common_service/support.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor(private supportService:SupportService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/service.jpg',
            title: 'Contact Us'
        }
    ]

    submit(form){
        var name = form.name;
        var email = form.email;
        var number = form.number;
        var message = form.message;
        var subject = form.subject;
        var token = localStorage.getItem('token');
        let details = {
            'user_email':email,
            'user_name':name,
            'mobile_number':number,
            'message':message,
            'service_type':'Contact Us',
            'subject':subject,
            'token':token,
            'type':'Contact Us'
    
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.supportService.sendServiceSupportEmail(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                   
                  })
            }else{
             Swal.fire({
                 title: 'Error',
            }).then((result) => {
                      
            })
                
            }
        })
    }

}