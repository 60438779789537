<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <p>{{Content.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-2 col-sm-12 col-md-12">

        </div>
        <div class="col-lg-4 col-md-12 mb-4">
            <div class="card h-100 packages">
                <div class="card-body">
                    <div class="text-center p-3">
                        <h5 class="card-title"><img style="max-width: 100%;"
                                src="/assets/img/zonealarm/powerdbydialog.jpg"> Monthly </h5>
                        <small>Billing Monthly</small>
                        <br><br>
                        <span class="h6">{{productPriceModel.month_price}}</span>/month
                        <br>
                        <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">
                            {{productPriceModel.month_old_price}}</span>
                        <br><br>

                    </div>
                </div>

                
            </div>
        </div>
        <div class="col-lg-4 col-md-12 mb-4">
            <div class="card h-100 packages">
                <div class="card-body">
                    <div class="text-center p-3">
                        <h5 class="card-title"><img style="max-width: 100%;"
                                src="assets/img/zonealarm/powerdbydialog.jpg"> Annually</h5>
                        <small>Billing Annually</small>
                        <br><br>
                        <span class="h6">{{productPriceModel.annual_price}}</span>/year
                        <br>
                        <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">
                            {{productPriceModel.annual_old_price}}</span>
                        <br><br>
                    </div>

                </div>

               
            </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-md-6">

        </div>
    </div>
</div>