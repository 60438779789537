import { Component, ViewChild } from '@angular/core';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SalesService } from '../../admin_service/sales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-all-pending-sales',
  templateUrl: './list-all-pending-sales.component.html',
  styleUrl: './list-all-pending-sales.component.scss'
})
export class ListAllPendingSalesComponent {
  rows = [];
  loading = false;
  actionsTemplate: any;
  cols = [];
  constructor(private salesService: SalesService) {
    salesService.getAll("Payment Success").subscribe(res => {
      this.rows = res.data;
      this.page.totalElements = this.rows.length;
      this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
      this.totalPages = this.page.totalPages;
      this.temp = this.rows;
      this.sortByColumn('id');
     
      console.log("sales list " + JSON.stringify(res.data));
    })

  }

  sortByColumn(column: string) {
    this.rows = [...this.rows].sort((a, b) => (a[column] > b[column] ? 1 : -1));
  }


  deleteRow(id: any) {

  }


  updateOrderStatus(order_id:any,trackingNumber:any){
    console.log("trackingNumber "+trackingNumber + " order_id "+order_id);
    if(trackingNumber==null || trackingNumber=='' ){
      Swal.fire({
        title:"Please enter order tracking number",
      }).then((result) => {
    //location.reload();
      })
    }else{
      this.salesService.updateInvoiceStatus("Delivered",order_id,trackingNumber).subscribe(res=>{
        Swal.fire({
          title:res.message,
        }).then((result) => {
          location.reload();
        })
      })
    }

  }


  //****************************** */
  temp = [];


  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.invoiceNumber.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
  

    // update the rows
  

   
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }



  page = {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.fetchData();
  }

  totalPages = 0;
  fetchData() {
    this.page.totalElements = this.rows.length;
    this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
    this.totalPages = this.page.totalPages;
  }

  prevPage() {
    if (this.page.pageNumber > 0) {
      this.page.pageNumber--;
      this.setPage({ offset: this.page.pageNumber });
    }
  }

  nextPage() {
    if (this.page.pageNumber < this.page.totalPages - 1) {
      this.page.pageNumber++;
      this.setPage({ offset: this.page.pageNumber });
    }
  }
}
