import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  sendServiceSupportEmail(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'servicesupport',details);
  }
}
