

<div class="page-title-area mt-0">
    <h1>Software Products</h1>
   
</div>

<section class="blog-area">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-12 col-md-12" style="align-items: center;">
                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let Content of softwareProductList | paginate: { itemsPerPage: 4, currentPage: blogRightSidebar }">
                        <div class="single-blog-post">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="post-image">
                                        <owl-carousel-o [options]="productsDetailsImageSlidesOptions" >
                                            <ng-template carouselSlide *ngFor="let product of Content.otherImages">
                                                <div class="image">
                                                    <a href="{{product.otherImgUrl}}" class="popup-btn">
                                                    <img src="{{product.otherImgUrl}}" alt="image">
                                                   </a>
                                                </div>
                                            </ng-template>
                                           
                                        </owl-carousel-o>
                                 
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="post-content">
                                        <h3>{{Content.title}}</h3>
                                        <p>{{Content.shortDescription}}</p>
         
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="blogRightSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
