export class MainCategory {
    id: number;
    category: string;
    description: string;
    created_at: string;
    updated_at: string;
   

    constructor(
        id: number,
        category: string,
        description:string,
        created_at:string,
        updated_at:string

    ) {
        this.id = id;
        this.category = category;
        this.description = description;
        this.created_at = created_at;
        this.updated_at = updated_at;
       
       
    }

    getId(): number {
        return this.id;
    }
    setId(id: number): void {
        this.id = id;
    }
    getCategory(): string {
        return this.category;
    }
    setCategory(category: string): void {
        this.category = category;
    }
    getCreatedAt(): string {
        return this.created_at;
    }
    setCreatedAt(created_at: string): void {
        this.created_at = created_at;
    }

    getUpdated_at(): string {
        return this.updated_at;
    }
    setUpdated_at(updated_at: string): void {
        this.updated_at = updated_at;
    }
  
    getDescription(): string {
        return this.description;
    }
    setDescription(description: string): void {
        this.description = description;
    }
    


}
