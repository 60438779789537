<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let SubscribeContent of subscribeArea;" style="background-color: rgba(2, 85, 112, 1);">
    <div class="container">
        <div class="section-title">
            
            <h2>{{SubscribeContent.title}}</h2>
            <img src="assets/img/tuya.png" style="height: 40px;">
        </div>

        <form class="newsletter-form">
            <label><i class='bx bx-envelope-open'></i></label>
            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
            <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> SUBSCRIBE NOW</button>
        </form>
    </div>
</section>
<!-- End Subscribe Area -->