<app-navbar-style-two></app-navbar-style-two>
<div style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <app-navbar-style-three></app-navbar-style-three>
            </div>
            <div class="col-md-8">

                <div class="container" style="padding-top: 10%;">
                    <div class="row" *ngIf="userCartDetailsListSize==0">
                        <div class="col">
                            <h2 style="text-align: center;">No Active Subscription</h2>
                            <br>

                        </div>
                    </div>
                    <div class="row" *ngIf="userCartDetailsListSize>0">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-10 col-md-12 col-sm-12">
                            <table>
                                <thead>
                                    <tr>
                                        <th><h5>Subscription</h5></th>
                                        <th><h5>Activation Date</h5></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr>
                                        <td>
                                            <h5 class="card-title"><img style="max-width: 50%;"
                                                    src="/assets/img/zonealarm/powerdbydialog.jpg">
                                                {{userCartDetailsList.package_name}} </h5>
                                        </td>
                                        <td>
                                            <h5> {{userCartDetailsList.activate_date}}</h5>
                                        </td>
                                    </tr>
                                    <br><br>
                                    <tr>
                                        <button class="default-btn" style="width: 100%"
                                            (click)="unSubscribed()">Unsubscribe</button>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>