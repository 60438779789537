import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CustomerServicePageComponent } from './components/pages/customer-service-page/customer-service-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { MyAccountPageComponent } from './components/pages/my-account-page/my-account-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { OrderTrackingPageComponent } from './components/pages/order-tracking-page/order-tracking-page.component';
import { RoomsPageComponent } from './components/pages/rooms-page/rooms-page.component';
import { ShopFullWidthPageOneComponent } from './components/pages/shop-full-width-page-one/shop-full-width-page-one.component';
import { ShopFullWidthPageTwoComponent } from './components/pages/shop-full-width-page-two/shop-full-width-page-two.component';
import { ShopLeftSidebarPageOneComponent } from './components/pages/shop-left-sidebar-page-one/shop-left-sidebar-page-one.component';
import { ShopLeftSidebarPageTwoComponent } from './components/pages/shop-left-sidebar-page-two/shop-left-sidebar-page-two.component';
import { ShopRightSidebarPageOneComponent } from './components/pages/shop-right-sidebar-page-one/shop-right-sidebar-page-one.component';
import { ShopRightSidebarPageTwoComponent } from './components/pages/shop-right-sidebar-page-two/shop-right-sidebar-page-two.component';
import { SimpleProductPageComponent } from './components/pages/simple-product-page/simple-product-page.component';
import { SliderProductPageComponent } from './components/pages/slider-product-page/slider-product-page.component';
import { StickyProductPageComponent } from './components/pages/sticky-product-page/sticky-product-page.component';
import { WishlistPageComponent } from './components/pages/wishlist-page/wishlist-page.component';
import { BedroomProductsComponent } from './components/pages/rooms-page/bedroom-products/bedroom-products.component';
import { KitchenRoomProductsComponent } from './components/pages/rooms-page/kitchen-room-products/kitchen-room-products.component';
import { LivingRoomProductsComponent } from './components/pages/rooms-page/living-room-products/living-room-products.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { AdminHomeComponent } from './components/pages/admin/admin-home/admin-home.component';
import { GuardAdmin, GuardSuperAdmin } from './components/admin_service/guard.admin';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ListAllProductsComponent } from './components/pages/list-all-products/list-all-products.component';
import { ListAllMainCategoryComponent } from './components/pages/list-all-main-category/list-all-main-category.component';
import { AddMainCategoryComponent } from './components/pages/add-main-category/add-main-category.component';
import { EditMainCategoryComponent } from './components/pages/edit-main-category/edit-main-category.component';
import { ListAllSubCategoryComponent } from './components/pages/list-all-sub-category/list-all-sub-category.component';
import { AddSubCategoryComponent } from './components/pages/add-sub-category/add-sub-category.component';
import { EditSubCategoryComponent } from './components/pages/edit-sub-category/edit-sub-category.component';
import { ListAllSoftwareProductsComponent } from './components/pages/list-all-software-products/list-all-software-products.component';
import { EditProductComponent } from './components/pages/edit-product/edit-product.component';
import { EditSoftwareProductComponent } from './components/pages/edit-software-product/edit-software-product.component';

import { ListAllPendingSalesComponent } from './components/pages/list-all-pending-sales/list-all-pending-sales.component';
import { ListAllDeliveredSalesComponent } from './components/pages/list-all-delivered-sales/list-all-delivered-sales.component';
import { UserLoginComponent } from './components/pages/user-login/user-login.component';
import { UserRegisterComponent } from './components/pages/user-register/user-register.component';
import { SelectPackageComponent } from './components/pages/select-package/select-package.component';
import { ViewZonealarmSubscriptionComponent } from './components/pages/view-zonealarm-subscription/view-zonealarm-subscription.component';
import { ViewProfileComponent } from './components/pages/view-profile/view-profile.component';
import { ViewChangePasswordComponent } from './components/pages/view-change-password/view-change-password.component';
import { ViewPrevoiusPackagesComponent } from './components/pages/view-prevoius-packages/view-prevoius-packages.component';
import { ViewPaymentHistoryComponent } from './components/pages/view-payment-history/view-payment-history.component';
import { AdminLoginComponent } from './components/pages/admin-login/admin-login.component';
import { AdminDashboardComponent } from './components/pages/admin-dashboard/admin-dashboard.component';
import { AdminActiveCustomersComponent } from './components/pages/admin-active-customers/admin-active-customers.component';
import { AdminAllCustomersComponent } from './components/pages/admin-all-customers/admin-all-customers.component';
import { AdminUnsubscriptionComponent } from './components/pages/admin-unsubscription/admin-unsubscription.component';

const routes: Routes = [
    {path: '', component: HomeDemoTwoComponent}, //Usingggggggg
    {path:'selectpackage/:package',component:SelectPackageComponent},
    {path: 'cart', component: CartPageComponent},
    {path:'view/account',component:ViewZonealarmSubscriptionComponent},
    {path:'change-password',component:ViewChangePasswordComponent},
    {path:'view/previous-packages',component:ViewPrevoiusPackagesComponent},
    {path:'view/payment-history',component:ViewPaymentHistoryComponent},
    {path: 'contact-us', component: ContactPageComponent},
    {path: 'resetpassword/:hash', component: ShopRightSidebarPageOneComponent},
    //**********Admin */
   // {path:'admin/login',component:AdminLoginComponent},
    {path:'admin/dashboard',component:AdminDashboardComponent},
    {path:'admin/active-customers',component:AdminActiveCustomersComponent},
    {path:'admin/all-customers',component:AdminAllCustomersComponent},
    {path:'admin/all-unsubsciption',component:AdminUnsubscriptionComponent},

    {path: 'view/profile', component: ViewProfileComponent},


    {path: 'shop/:category', component: ShopLeftSidebarPageOneComponent},
    {path: 'product_details/:itemId', component: SliderProductPageComponent},
    {path:'customer/login',component:UserLoginComponent},
    {path:'customer/register',component:UserRegisterComponent},
    {path: 'login', component: MyAccountPageComponent},
    {path: 'register', component: OrderTrackingPageComponent},
    
    {path: 'viewshort/:itemId',component:PartnerComponent},
    {path: 'wishlist', component: WishlistPageComponent},
    
    {path: 'service_support', component: CustomerServicePageComponent},
    {path: 'about_us', component: AboutPageComponent},
    
    {path: 'checkout', component: CheckoutPageComponent},
    {path: 'address', component: StickyProductPageComponent},
    {path: 'add_address/:type', component: RoomsPageComponent},
    {path: 'edit_address/:type/:id',component:SimpleProductPageComponent},
    {path: 'payment/status', component: BlogGridComponent},
    {path: 'search-product/:searchtext', component: BlogDetailsComponent},
    {path: 'software-products', component: BlogRightSidebarComponent},
    {path: 'video-gallery', component: ComingSoonPageComponent},
    
    {path: 'privacy_policy', component: ShopLeftSidebarPageTwoComponent},
    {path: 'terms_of_use', component: ShopFullWidthPageTwoComponent},
   



   //****************Admin ***************** */
   {path:'admin/registeruser',component:KitchenRoomProductsComponent,canActivate:[GuardSuperAdmin]},
   {path:'admin/loginuser',component:BedroomProductsComponent},
   {path: 'admin/home', component: HomeDemoThreeComponent,canActivate:[GuardAdmin]},

   {path:'admin/list-all-products',component:ListAllProductsComponent,canActivate:[GuardAdmin]},
   {path:'admin/add-product',component:LivingRoomProductsComponent,canActivate:[GuardAdmin]},
   {path:'admin/edit-product/:id',component:EditProductComponent,canActivate:[GuardAdmin]},

   {path:'admin/all-software-products',component:ListAllSoftwareProductsComponent,canActivate:[GuardAdmin]},
   {path:'admin/add-software-product', component: ShopRightSidebarPageTwoComponent,canActivate:[GuardAdmin]},
   {path:'admin/edit-software-product/:id',component:EditSoftwareProductComponent,canActivate:[GuardAdmin]},

   {path:'admin/list-all-categories',component:ListAllMainCategoryComponent,canActivate:[GuardAdmin]},
   {path:'admin/add-category',component:AddMainCategoryComponent,canActivate:[GuardAdmin]},
   {path:'admin/edit-category/:id',component:EditMainCategoryComponent,canActivate:[GuardAdmin]},
   //{path:'admin/view-category/:id',component:EditMainCategoryComponent,canActivate:[GuardAdmin]},

   {path:'admin/list-all-sub-categories',component:ListAllSubCategoryComponent,canActivate:[GuardAdmin]},
   {path:'admin/add-sub-category',component:AddSubCategoryComponent,canActivate:[GuardAdmin]},
   {path:'admin/edit-sub-category/:id',component:EditSubCategoryComponent,canActivate:[GuardAdmin]},
  // {path:'admin/view-sub-category/:id',component:EditMainCategoryComponent,canActivate:[GuardAdmin]},

  {path:'admin/list-all-pending-sales',component:ListAllPendingSalesComponent,canActivate:[GuardAdmin]},
  {path:'admin/list-all-delivered-sales',component:ListAllDeliveredSalesComponent,canActivate:[GuardAdmin]},
   

   
    

    

    {path: 'index-1', component: HomeDemoOneComponent},
   
    {path: 'add_shipping_address', component: StickyProductPageComponent},
    {path: 'edit_shipping_address/:id', component: ShopRightSidebarPageTwoComponent},

    
    {path: 'shop-full-width-1', component: ShopFullWidthPageOneComponent},
    
   
   
   
    
    {path: 'faq', component: FaqPageComponent},
    
    
    // Here add new pages component

    {path: '**', component: NotFoundPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {}),NgxDatatableModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }