<aside>
<ul class="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist" >
    <li class="nav-item" style="padding-top: 20%;">
        <a routerLink="/view/account" class="nav-link"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                class='bx bx-right-arrow-alt'></i>Subscription </a>
    </li>
    <li class="nav-item">
        <a routerLink="/view/previous-packages" class="nav-link"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                class='bx bx-right-arrow-alt'></i>Previous Packages </a>
       
    </li>
    <li class="nav-item">
        <a routerLink="/view/payment-history" class="nav-link"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                class='bx bx-right-arrow-alt'></i>Payment History</a>
       
    </li>

    <li class="nav-item">
        <a routerLink="/change-password" class="nav-link"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                class='bx bx-right-arrow-alt'></i>Change Password</a>
      
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab-password-link" data-toggle="tab" href="/" role="tab"
            aria-controls="tab-password" aria-selected="false" (click)="logOutClicked()"><i
                class='bx bx-right-arrow-alt'></i>Sign Out</a>

    </li>
</ul>
</aside>