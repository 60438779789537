import { Component, OnInit } from '@angular/core';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
    userCartDetailsList:any;
    userCartDetailsListSize:number=0;
    termsAccepts:boolean=false;
    token = localStorage.getItem("token");
    constructor(private cartService:AddToCartService) { 
        let token = localStorage.getItem("token");
        if(!(token==null || token=='empty')){
            let details = {
                'token':token,
              }
            const uploadFormData = new FormData();
            uploadFormData.append('details', JSON.stringify(details));
            this.cartService.getCartDetails(uploadFormData).subscribe(res=>{
                this.userCartDetailsList = res.data;
                if(this.userCartDetailsList!=null){
                    this.userCartDetailsListSize=1;
                }
                console.log("this.userCartDetailsList "+JSON.stringify(this.userCartDetailsList));
            })
        }
       
       
    }

    ngOnInit(): void {
    }


    showPlaceOrderBtn:boolean=true;
    checkoutClicked(){
        if(!this.termsAccepts){
            Swal.fire({
                title: 'Please Accept the Term and Conditons!',
              }).then((result) => {
                
              })
        }else{
            this.showPlaceOrderBtn = !this.showPlaceOrderBtn;
            this.cartService.placeOrder(this.userCartDetailsList).subscribe(res=>{
                Swal.fire({
                    title: res.message,
                  }).then((result) => {
                    
                  })
                  if(res.status=='error'){
                    this.showPlaceOrderBtn = !this.showPlaceOrderBtn;
                  }
            })
        }
    }

    pageTitle = [
        {
            bgImage: 'https://tedismart.com/assets/images/TopHeaderAnimation.gif',
            title: 'Shopping Cart'
        }
    ]

}