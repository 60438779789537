import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getProductsByCategories(category:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductsbycategory?category='+category);
   
  }

  getNewProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getnewproducts');

  }

  getTopRatedProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/get_top_rated_products');

  }

  getSliderProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl
      +'product/get_slider_products');

  }
  getTopSellingProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/get_top_selling_products');

  }
  getAllProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getallproduct');
   
  }

  getProductDetailsById(itemId:any){
    let token = localStorage.getItem('token');
    if(token=='undefined'){
      token="NO";
    }
    console.log('token getProductDetailsById '+token);
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductdetilsbyid?id='+itemId+'&token='+token);
  }
}
