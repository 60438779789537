import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { customerModel } from '../../model/customerModel';
import { VerificationModel } from '../../model/VerificationModel';

@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent {
  isDropdownOpen = false;
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  token:any;
  accShow:any;
  logOutClicked(){
    localStorage.setItem("token","empty");
    localStorage.setItem("accShow","notShow");
    window.location.href="/";
  }
    constructor(private userlogin:UserloginService) {
      
      this.token = localStorage.getItem('token');
      this.accShow = localStorage.getItem('accShow');
      console.log("token "+this.token);
      console.log("accShow "+this.accShow);
     }

     isLoginVisible: boolean = false;

     showLogin() {
       this.isLoginVisible = true;
     }
 
  phoneNumber: string = '+94766555550'; // Replace with the actual phone number

  makeCall(): void {
    const url = `https://wa.me/${this.phoneNumber}`;
    window.open(url, '_blank');
  }

   //*************************Login Start *******************************/
  
    //*************************Login Start *******************************/
    lostPassword(email:any){
      if(email==''){
        Swal.fire({
          title: 'Please enter Email!',
        }).then((result) => {
          
        })
      }else{
        this.userlogin.forgotPasswordZone(email).subscribe(res=>{
          Swal.fire({
            title: res.message,
          }).then((result) => {
            
          })
          if(res.status=='success'){
            location.reload();
          }
        })
      }

    }
 

  loginToSystem(email:any,password:any){
    if(email==''){
      Swal.fire({
        title: 'Please enter Email!',
      }).then((result) => {
        
      })
    }else if(password==''){

      Swal.fire({
        title: 'Please enter Password!',
      }).then((result) => {
        
      })
    }else{
    this.customerModel = new customerModel(0,email,password,"","");
    
    this.userlogin.loginUser( this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          let token = res.data;
          localStorage.setItem('token',token);
          localStorage.setItem('type','customer');
          localStorage.setItem('accShow','show');
          let packageSelected = localStorage.getItem('package');
          if(packageSelected!='NO'){
            window.location.href='selectpackage/'+packageSelected;
          }else{
            location.reload();
          }
;          
          
        }
        
    })
    }
  }

  loginFormDisplay:boolean=true;
  registerClicked(){
    this.loginFormDisplay=!this.loginFormDisplay;
  }

  customerModel!:customerModel;
  verificationModel!:VerificationModel;
  registerButtonClicked:boolean=false;
  customerRegisteredEmail:any;
  registerUser(email:any,password:any){
    if(email==''){
      Swal.fire({
        title:'Please enter email!',
      }).then((result) => {
       
      })
    }else if(password==''){
      Swal.fire({
        title:'Please enter password!',
      }).then((result) => {
       
      })
    }else{
     
    this.customerModel = new customerModel(0,email,password,"","");
    this.userlogin.registerUser(this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          this.customerRegisteredEmail = email;
          this.registerButtonClicked = true;
        }
        
    })
    }
  }

  verifyAccount(emailcode:any){
     if(emailcode==''){
      Swal.fire({
        title:'Please enter email verification code!',
      }).then((result) => {
       
      })
    }else{
    this.verificationModel = new VerificationModel( this.customerRegisteredEmail,emailcode,'',0);
    this.userlogin.verification(this.verificationModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
          
        })

        if(status=='success'){
          this.registerClicked();
        }

        
        
    })
    }
  }


}