import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductService } from '../../common_service/product.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AddToCartService } from '../../common_service/add-to-cart.service';
import { AddToWishListService } from '../../common_service/add-to-wish-list.service';

@Component({
	selector: 'app-slider-product-page',
	templateUrl: './slider-product-page.component.html',
	styleUrls: ['./slider-product-page.component.scss']
})
export class SliderProductPageComponent implements OnInit {

	constructor(private route: ActivatedRoute, private productService: ProductService, private addToCartService: AddToCartService,private addToWishListService:AddToWishListService) { }
	itemId: any;
	selectedProductDetails: any;
	images: Array<any> = [];
	title: any;
	price: any;
	shortDescription: any;
	delivery: any;
	stDelivery: any;
	deliveryWithin: any;
	warrenty: any;
	productInfo: any;
	additionalInfo: any;
	otherDetails: any;
	category: any;

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.itemId = params['itemId'];
			console.log("product_id " + this.itemId);
			this.productService.getProductDetailsById(this.itemId).subscribe(res => {
				this.selectedProductDetails = res;
				this.images = this.selectedProductDetails.otherImages;
				this.title = this.selectedProductDetails.title;

				this.price = this.selectedProductDetails.product_price;
				this.shortDescription = this.selectedProductDetails.shortDescription;
				this.delivery = this.selectedProductDetails.delivery;
				this.stDelivery = this.selectedProductDetails.standardDelivery;
				this.deliveryWithin = this.selectedProductDetails.deliveryWithin;
				this.warrenty = this.selectedProductDetails.warrenty;
				this.productInfo = this.selectedProductDetails.productInformation;
				this.additionalInfo = this.selectedProductDetails.additionalInformation;
				this.otherDetails = this.selectedProductDetails.otherProductDetails;
				this.category = this.selectedProductDetails.category;
				console.log("this.selectedProductDetails " + JSON.stringify(this.selectedProductDetails));

			})
		});

	}

	pageTitle = [
		{
			bgImage: 'assets/img/page-title-bg.jpg',
			title: 'Ergonomic Desk Sofa'
		}
	]

	addToWishList(productId:any){
        let token = localStorage.getItem('token');
        console.log("productId "+productId);
        if(token==null){
            Swal.fire({
                title: 'You need to log in before you add an item!',
              }).then((result) => {
                window.location.href = "/login";
              })
            
        }else{
            let details = {
                'token':token,
                'productId':productId
              }
              const uploadFormData = new FormData();
              uploadFormData.append('details', JSON.stringify(details));
            this.addToWishListService.addToWishList(uploadFormData).subscribe(res=>{
                let status = res.status;
                if(status=="success"){
                    Swal.fire({
                        title: 'Item successfully added to the wish list!',
                      }).then((result) => {
                        
                      })  
                }else{
                    if(status=='AlreadyIn'){
                        Swal.fire({
                            title: 'This product already is in the wish list!',
                          }).then((result) => {
                            
                          }) 
                    }else{
                        Swal.fire({
                            title: 'There is an error with adding to the wish list!',
                          }).then((result) => {
                            
                          })  
                    }
                  
                }
            
            })

        }
        

     }
	

	userSelectedOtherDetails: Array<any> = [];
	otherDetailsSelected(type: any, value: any) {
		console.log("type " + type + " value " + value + " " + this.otherDetails.length);
		for (let i = 0; i < this.otherDetails.length; i++) {
			let de = this.otherDetails[i];
			let ty = de.type;
			let val = de.values;
			console.log("type " + type + " value " + value + " ty " + ty);
			if (ty == type) {
				let obj = {
					'type': ty,
					'value': value
				}
				this.userSelectedOtherDetails.push(obj);
			} else {
				if (val.length == 1) {
					let obj2 = {
						'type': ty,
						'value': val[0]
					}
					this.userSelectedOtherDetails.push(obj2);

				}
			}
		}
		console.log("user " + JSON.stringify(this.userSelectedOtherDetails));
	}

	recepEmail: string = 'NO';
	addToCart(productId: any, quantity: any) {
		if (this.userSelectedOtherDetails.length == 0 && this.otherDetails.length > 0) {
			for (let i = 0; i < this.otherDetails.length; i++) {
				let de = this.otherDetails[i];
				let ty = de.type;
				let val = de.values;
				let obj2 = {
					'type': ty,
					'value': val[0]
				}
				this.userSelectedOtherDetails.push(obj2);
			}
		}
		let token = localStorage.getItem('token');
		console.log("token " + token + " " + (token == null));
		if (token == null || token == 'null') {
			Swal.fire({
				title: 'You need to log in before you add an item!',
			}).then((result) => {
				window.location.href = "/login";
			})

		} else {
			if (this.category =='Gift Vouchers' && this.recepEmail == 'NO') {
				Swal.fire({
					title: 'Please  enter email of recipient!',
				}).then((result) => {
				})
			} else {
				let details = {
					'token': token,
					'productId': productId,
					'quantity': quantity,
					'userSelectedOtherDetails': this.userSelectedOtherDetails,
					'recepEmail': this.recepEmail
				}
				const uploadFormData = new FormData();
				uploadFormData.append('details', JSON.stringify(details));
				this.addToCartService.addToCart(uploadFormData).subscribe(res => {
					let status = res.status;
					if (status == "success") {
						Swal.fire({
							title: 'Item successfully added to the cart!',
						}).then((result) => {
						})
					} else {
						Swal.fire({
							title: 'There is an error with adding to the cart!',
						}).then((result) => {
						})
					}
				})
			}
		}
	}

	productsDetailsImageSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items: 3,
			},
			768: {
				items: 4,
			},
			1200: {
				items: 4,
			}
		}
	}

}