

<nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar" class="navbar-area">
    <div class="container-fluid" style="background-image: url('assets/img/zonealarm/zbackground2.png'); color: white;">
        <!-- Create a wrapper for the background image -->
        <div class="background-wrapper" style="margin-left: 20px;">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/zonealarm/zone_logos.png" alt="logo"
                        style="height: 40px;"></a>

                <!-- Move the toggle button out of the flow using position: absolute -->
                <button class="navbar-toggler order-lg-last" type="button" (click)="toggleDropdown()"
                    data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent"
                    aria-expanded="false" aria-label="Toggle navigation"
                    style="position: absolute; right: 20px; top: 15px; z-index: 1;color: white;">
                    <span class="navbar-toggler-icon custom-toggler-icon"></span>
                </button>
                <div id="navbarContent"
                    [ngClass]="{'collapse navbar-collapse dropdown-menu-right': true, 'show': isDropdownOpen}"
                    style="margin-top: 0px;">
                    <ul class="navbar-nav" style="margin-top: 0px;margin-left: 15%;margin-top: 2%;">
                        <li class="nav-item" style="margin-right: 20px;"><a routerLink="/admin/dashboard" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div style="color: white;"><i class='bx bx-home'></i> Home</div>
                            </a></li>
                        <li class="nav-item" style="margin-right: 20px;" *ngIf="token!=null && accShow=='show'"><a routerLink="/admin/active-customers" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div style="color: white;"><i class='bx bx-user'></i>Active Subscription</div>
                            </a></li>

                            
                            <li class="nav-item" style="margin-right: 20px;" *ngIf="token!=null && accShow=='show'"><a routerLink="/admin/all-unsubsciption" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div style="color: white;"><i class='bx bx-user'></i>Unsubscription</div>
                            </a></li>
                            <li class="nav-item" style="margin-right: 20px;" *ngIf="token!=null && accShow=='show'"><a routerLink="/admin/all-customers" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div style="color: white;"><i class='bx bx-user'></i>All Customers</div>
                            </a></li>

                        <li class="nav-item" style="margin-right: 40px;cursor: pointer;"
                            *ngIf="token==null || token=='empty' "><a data-bs-toggle="modal"
                                data-bs-target="#productsQuickView2" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" (click)="showLogin()">
                                <div style="color: white;"><i class='bx bx-user'></i> Login</div>
                            </a></li>
                            

                            <li class="nav-item" style="margin-right: 20px;"  *ngIf="token!=null && accShow=='show'"><a routerLink="/admin/dashboard" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="logOutClicked()">
                                <div style="color: white;"><i class='bx bx-user'></i> Log Out</div>
                            </a></li>

                        
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</nav>





<div class="modal fade" id="productsQuickView2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                        <button type="button" class="close2" data-bs-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true"><i class='bx bx-x' style="font-size:28px;"></i></span>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="loginFormDisplay">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;">Login</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                                <div class="form-group">
                                    <label>Enter Email</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email" value="">
                                </div>
                                <div class="form-group" >
                                    <label>Enter Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>
                                <!--div class="form-group" >
                                    <a  (click)="lostPassword(email.value)" class="lost-your-password">Forgot your password?</a>
                                </div-->
                                
                                
                                <button type="submit"  (click)="loginToSystem(email.value,password.value)">LOGIN</button>
                                <br><br>
                            </form>
                           
                        </div>
                    </div>
                </div>

             
            </div>
        </div>
    </div>
</div>