<app-navbar-style-two></app-navbar-style-two>
<div style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <app-navbar-style-three></app-navbar-style-three>
            </div>

            
            <div class="col-lg-9 col-md-9">
                <div class="container" style="padding-top: 4%;">
                    <div class="row" *ngIf="userCartDetailsListSize==0">
                        <div class="col">
                            <h2 style="text-align: center;">No Payment</h2>
                            <br>

                        </div>
                    </div>
                    <div class="row" *ngIf="userCartDetailsListSize>0">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <ngx-datatable #table class="material" [columns]="cols" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                                [rows]="userCartDetailsList.slice(page.pageNumber * page.size, (page.pageNumber + 1) * page.size)"
                                [limit]="page.size">


                                <ngx-datatable-column name="Ivoice ID" prop="invoice_id" [sortable]="true">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="flex items-center gap-2">
                                            <div class="font-semibold">{{ row.invoice_id }}</div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Package" prop="package_name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="font-semibold">{{row.package_name }}</div>

                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Price (LKR)" prop="price_with_tax">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="flex items-center gap-2">
                                            <div class="font-semibold">{{row.price_with_tax }}</div>

                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Date" prop="created_at">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="flex items-center gap-2">
                                            <div class="font-semibold">{{row.created_at }}</div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                            </ngx-datatable>
                            <div class="pagination-controls">
                                <button (click)="prevPage()" [disabled]="page.pageNumber === 0">Previous</button>
                                <span>{{page.pageNumber + 1}} / {{totalPages}}</span>
                                <button (click)="nextPage()"
                                    [disabled]="page.pageNumber >= totalPages - 1">Next</button>
                            </div>
                            <!--table style="border-bottom: 1px solid black;">
                                <thead style="border-bottom: 1px solid black;">
                                    <tr>
                                        <th><h6>Invoice ID</h6></th>
                                        <th><h6 style="text-align: center;padding: 2%;">Package</h6></th>
                                        <th><h6 style="text-align: center;padding: 2%;">Price (LKR)</h6></th>
                                        <th><h6 style="text-align: center;padding: 2%;">Date</h6></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr *ngFor="let subscription of userCartDetailsList">
                                        <td>
                                            <p style="text-align: center;padding: 2%;"> {{subscription.invoice_id}}</p>
                                        </td>
                                        <td>
                                            <p class="card-title" style="text-align: center;padding: 2%;">
                                            {{subscription.package_name}}  </p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;padding: 2px;padding: 20px;"> {{subscription.price_with_tax}}</p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;padding: 2px;padding: 20px;"> {{subscription.created_at }} </p>
                                        </td>
                                       

                                    </tr>
                                    <br>
                                   
                                </tbody>
                            </table-->
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
    </div>
</div>