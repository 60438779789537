
<app-navbar-style-three></app-navbar-style-three>
<br>
<div class="section-title">
    <h4>Create Category</h4>
</div>

<section class="order-tracking-area">
    <div class="container">
        <div class="order-tracking-content">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="form-group">
                    <label>Enter Category Name</label>
                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Enter Category">
                </div>

                <div class="form-group">
                    <label>Enter Description</label>
                    <textarea required ngModel name="description" #description="ngModel" class="form-control" id="description" placeholder="Enter Description"></textarea>
                </div>

                <button type="submit" class="default-btn">CREATE</button>
            </form>
        </div>
    </div>
</section>
