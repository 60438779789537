import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BannersService } from 'src/app/components/common_service/banners.service';
import { ProductService } from 'src/app/components/common_service/product.service';

@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {

    mainBannerItem:Array<any> =[];
    constructor(private productService:ProductService,private bannersService:BannersService) {
        /*productService.getSliderProducts().subscribe(res=>{
            this.mainBannerItem = res;
        })  */
        
        bannersService.getBanners("HOME").subscribe(res=>{
            this.mainBannerItem = res;
        })
     }

    ngOnInit(): void {
    }

    /*mainBannerItem = [
        {
            title: 'Bedroom Deals Up To 40% OFF',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'shop-full-width-2',
            image: 'assets/img/banner-img1.png'
        },
        {
            title: 'We Serve Your Dream Furniture',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'shop-full-width-2',
            image: 'assets/img/banner-img2.png'
        },
        {
            title: 'Baby Essentials Up To 50% OFF',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'shop-full-width-2',
            image: 'assets/img/banner-img3.png'
        }
    ]*/

    homeSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		margin: 5,
		dots: false,
		autoplay: true,
		autoHeight: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}