<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<section class="profile-authentication-area ptb-20">
    <div class="container" style="padding-left: 25%;padding-right: 25%;">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="login-form">
                    <br>
                    <h2 style="text-align: center;">Register</h2>

                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" style="padding-left: 10px;padding-right: 10px;">
                        <div class="form-group">
                            <label>Username</label>
                            <input required minlength="3" maxlength="50" ngModel name="username" type="text" #username="ngModel" class="form-control" id="username" placeholder="Enter Username">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>

                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input required minlength="3" maxlength="50" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                        </div>

                     
                        <button type="submit">REGISTER</button>
                    </form>
                    <br>
                    <h6 style="text-align: center;color: rgb(3, 3, 52);">OR</h6>
                    
                    <h6 style="text-align: center;color: rgb(3, 3, 52);">
                        <a routerLink="/customer/login" class="tiltle" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Login
                                </a>
                    </h6>
                </div>
            </div>
        </div>
    
    </div>
</section>

