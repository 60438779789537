<app-navbar-style-two></app-navbar-style-two>
<div style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
            <app-navbar-style-three></app-navbar-style-three>
            </div>
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-lg-7 col-md-7">
                <div class="login-form">
                    <h2 style="text-align: center; margin-top: 10px;">Change Password</h2>
                    <form #contactForm="ngForm" style="padding-left: 10px; padding-right: 10px;">
                        <div class="form-group">
                            <label>Email</label>
                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email" readonly [(ngModel)]="customerModel.email">
                        </div>
                        <div class="form-group">
                            <label>Current Password *</label>
                            <input required minlength="3" maxlength="10" ngModel name="curpassword" type="password" #curpassword="ngModel" class="form-control" id="curpassword" placeholder="Enter Current Password">
                        </div>
                        <div class="form-group">
                            <label>Enter New Password *</label>
                            <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                        </div>
                        <div class="form-group">
                            <label>Confirm Password *</label>
                            <input required minlength="3" maxlength="10" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                        </div>
                        <button type="submit" (click)="changePassword(email.value, curpassword.value, password.value, confirmpassword.value)">SUBMIT</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-1 col-md-1"></div>
        </div>
    </div>
</div>