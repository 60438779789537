import { Component } from '@angular/core';
import { SubscriptionService } from '../../common_service/subscription.service';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-view-prevoius-packages',
  templateUrl: './view-prevoius-packages.component.html',
  styleUrl: './view-prevoius-packages.component.scss'
})
export class ViewPrevoiusPackagesComponent {
  userCartDetailsList:Array<any>=[];
  userCartDetailsListSize:number=0;
  termsAccepts:boolean=false;
  constructor(private subscriptionService:SubscriptionService) { 
      let token = localStorage.getItem("token");
      let details = {
          'token':token,
        }
      const uploadFormData = new FormData();
      uploadFormData.append('details', JSON.stringify(details));
      this.subscriptionService.getUserAllSubscriptionDetails(uploadFormData).subscribe(res=>{
          this.userCartDetailsList = res.data;
          this.userCartDetailsListSize=this.userCartDetailsList.length;
         
          console.log("this.userCartDetailsList "+JSON.stringify(this.userCartDetailsList));
      })
     
  }

  cols=[];
  
  page = {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.fetchData();
  }

  totalPages = 0;
  fetchData() {
    this.page.totalElements = this.userCartDetailsList.length;
    this.page.totalPages = Math.ceil(this.page.totalElements / this.page.size);
    this.totalPages = this.page.totalPages;
  }

  onPageChange(event: any) {
    this.page.pageNumber = event.page;
  }

  prevPage() {
    if (this.page.pageNumber > 0) {
      this.page.pageNumber--;
      this.setPage({ offset: this.page.pageNumber });

    }
  }

  nextPage() {
    if (this.page.pageNumber < this.page.totalPages - 1) {
      this.page.pageNumber++;
      this.setPage({ offset: this.page.pageNumber });
    }
  }
  ColumnMode = ColumnMode;
}
