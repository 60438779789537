<app-navbar-style-three></app-navbar-style-three>

<div class="section-title" >
    <h2>All Products</h2>
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <a routerLink="/admin/add-product" class="btn btn-primary gap-2">
                Add New
            </a>
            <br><br>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <input type="text" style="padding:8px;margin:15px auto;width:30%;"
                        placeholder="Type to filter by Title..."
                        (keyup)="updateFilter($event)" />
                    <ngx-datatable #table class="material" [columns]="cols" [columnMode]="ColumnMode.force"
                        [headerHeight]="50" [footerHeight]="50" rowHeight="auto"  
                        [rows]="rows.slice(page.pageNumber * page.size, (page.pageNumber + 1) * page.size)"
                        [limit]="page.size">


                        <ngx-datatable-column name="ID" prop="id" [sortable]="true">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                    <div class="font-semibold">#{{ row.id }}</div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Title" prop="title">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="font-semibold">{{row.title }}</div>
                                
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Category" prop="category">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                  <div *ngFor="let category of categoryList">
                                    <div *ngIf="category.id==row.category">{{category.name }}</div>
                                </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Sub Category" prop="subcategory">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="flex items-center gap-2">
                                <div *ngFor="let subcategory of subcategoryList">
                                  <div *ngIf="subcategory.id==row.sub_category">{{subcategory.name }}</div>
                              </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>

                        <ngx-datatable-column name="Created At" prop="createdDateTime">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="flex items-center gap-2">
                                    <div class="font-semibold">{{ row.createdDateTime }}</div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Actions" prop="actions">
                            <ng-template let-row="row" ngx-datatable-cell-template>

                                <a routerLink="/admin/edit-product/{{row.id}}" class="hover:text-info"
                                    style="padding-right: 2%;">
                                    <i class='bx bx-edit'></i>
                                </a>
                               
                                <button type="button" class="hover:text-danger" (click)="deleteRow(row.id)"
                                    style="border: none;background-color: white;">
                                    <i class='bx bx-trash'></i>
                                </button>

                            </ng-template>
                        </ngx-datatable-column>

                    </ngx-datatable>
                    <div class="pagination-controls">
                        <button (click)="prevPage()" [disabled]="page.pageNumber === 0">Previous</button>
                        <span>{{page.pageNumber + 1}} / {{totalPages}}</span>
                        <button (click)="nextPage()" [disabled]="page.pageNumber >= totalPages - 1">Next</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</section>

