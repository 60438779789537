import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-rooms-page',
    templateUrl: './rooms-page.component.html',
    styleUrls: ['./rooms-page.component.scss']
})
export class RoomsPageComponent implements OnInit {

    constructor(private userlogin:UserloginService,private route: ActivatedRoute) { }

    addressType:any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.addressType = params['type']; 
        })
    }

    submit(form){
        var line1 = form.line1;
        var line2 = form.line2;
        var line3 = form.line3;
        var city = form.city;
        var postalCode = form.postalCode;
        var country = form.country;

        let address = {
            'line1':line1,
            'line2':line2,  
            'line3':line3,
            'city':city,
            'postalCode':postalCode,
            'country':country,
            'type':this.addressType
        }

        let token = localStorage.getItem('token');
        let details = {
            'address':address,
            'token':token
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.saveAddress(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                   location.reload();
                  })
            }else{
                if(status == 'Invalid_user'){
                    Swal.fire({
                        title: 'Invalid User',
                      }).then((result) => {
                        
                      })
                }else if(status=="already_billing_address"){
                    Swal.fire({
                        title: 'Billing Address already exit',
                      }).then((result) => {
                        
                      })
                }
                else{
                    Swal.fire({
                        title: 'Error',
                      }).then((result) => {
                       
                      })
                }
            }
        })
    }

}