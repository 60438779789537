import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductServiceService } from '../../admin_service/product-service.service';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrl: './edit-product.component.scss'
})
export class EditProductComponent {
  productAvailabilityList = [
    { 'value': 'In Stock' },
    { 'value': 'Out Of Stock' },
    { 'value': 'Pre-Order' },
    { 'value': 'Available In Retails' },
    { 'value': 'Not In Sri Lanka' },
  ]

  warrentyList = [
    { 'value': '6 Month Warranty' },
    { 'value': '12 Month Warranty' },
    { 'value': 'No Warranty' },
  ]

  saleTypesList = [
    { 'value': 'All' },
    { 'value': 'Not Available In Stock' },
  ]

  id: number = 0;
  data: any;
  sale_type: any;
  product_availability: any;
  warrenty: any;
  otherImageList: Array<any> = [];
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.productService.getProductDetailsByIdAdmin(this.id).subscribe(res => {
        this.data = res.data;
        this.selectedCategory = this.data.category;
        this.selectedSubCategory = this.data.sub_category;
        this.sale_type = this.data.sale_type;
        this.product_availability = this.data.product_availability;
        this.warrenty = this.data.warrenty;
        this.productInformationList = this.data.productInformation;
        this.productAdditonalInfoList = this.data.additionalInformation;
        this.otherImageList = this.data.otherImages;

        console.log("product details " + JSON.stringify(res.data));

      })
    })
  }


  selectedProductAvailability = 'In Stock';
  selectProductAvailability(value: any) {
    this.selectedProductAvailability = value;
  }

  selectedWarrenty = '6 Month Warranty';
  selectWarrenty(value: any) {
    this.selectedWarrenty = value;
  }

  selectedSalesType = 'All';
  selectSaleType(value: any) {
    this.selectedSalesType = value;
  }

  mainCategoriesList: Array<any> = [];
  subCategoriesList: Array<any> = [];
  constructor(private productService: ProductServiceService, private maserfilesService: MaserfilesService
    , private route: ActivatedRoute
  ) {
    maserfilesService.getAll('category').subscribe(res => {
      this.mainCategoriesList = res;
    })

    maserfilesService.getAll('subcategory').subscribe(res => {
      this.subCategoriesList = res;
    })
  }

  selectedCategory = 0;
  selectCategory(id: any) {
    this.selectedCategory = id;
  }

  selectedSubCategory = 0;
  selectSubCategory(id: any) {
    this.selectedSubCategory = id;
  }



  //*********************Other Details ST ********************************* */
  productOtherTypes: string = '';
  productOtherTypesValue: string = '';
  addOtherList: Array<any> = [];
  currentType: string = '';
  doneOtherDetialsList: Array<any> = [];
  addOtherDetails(type: any, typeVal: any) {
    this.addOtherList.push(typeVal);
    this.currentType = type;
    this.productOtherTypesValue = '';
  }
  doneAdding() {
    let types = {
      'type': this.currentType,
      'values': this.addOtherList
    }
    this.doneOtherDetialsList.push(types);
    this.productOtherTypes = '';
    this.addOtherList = [];
  }

  deleteOtherTypes(type: any) {
    const index = this.doneOtherDetialsList.indexOf(type);
    if (index > -1) {
      this.doneOtherDetialsList.splice(index, 1); // Remove one item at the found index
    }
  }

  //*********************Prodcut Info ********************************* */
  productInformation: string = '';
  productInformationList: Array<any> = [];
  addToProductInformationToList() {
    if (this.productInformation.length == 0) {
      Swal.fire({
        title: 'Please enter product information',
      }).then((result) => {

      })
    } else {
      this.productInformationList.push(this.productInformation);
      this.productInformation = '';
    }
  }

  deleteProductInfoInList(highlight: any) {
    const index = this.productInformationList.indexOf(highlight);
    console.log("delete " + index);
    if (index > -1) {
      this.productInformationList.splice(index, 1);
    }
  }

  //*********************Prodcut Additional Info ********************************* */
  additionInfoType: string = '';
  additionInfoValue: string = '';
  productAdditonalInfoList: Array<any> = [];
  addSpecification() {
    if (this.additionInfoType.length == 0) {
      Swal.fire({
        title: 'Please enter product addional information',
      }).then((result) => {

      })
    } else {
      let spObj = {
        'type': this.additionInfoType,
        'value': this.additionInfoValue
      }
      this.productAdditonalInfoList.push(spObj);
      this.additionInfoType = '';
      this.additionInfoValue = '';
    }
  }

  deleteAdditionalInfo(info: any) {
    const index = this.productAdditonalInfoList.indexOf(info);
    if (index > -1) {
      this.productAdditonalInfoList.splice(index, 1);
    }
  }


  //************** Submit Product Details */
  docId: string = '';
  submit(form) {
    var productTitle = form.productTitle;
    var category = this.selectedCategory;
    var subcategory = this.selectedSubCategory;
    var productCompany = form.productCompany;
    var productPrice = form.productPrice;
    var price_value = form.productPriceValue;
    var simple_description = form.simpleDesciption;
    var productDesciption = form.productDesciption;
    var shippingCharge = form.shippingCharge;
    var availability = this.selectedProductAvailability;
    var warrenty = this.selectedWarrenty;
    var saleType = this.selectedSalesType;
    var availableStock = form.availableStock;
    var height = form.height;
    var width = form.width;
    var length = form.length;
    var volumetric_weight = form.volWeight;
    var weight = form.weight;

    var standardDelivery_value = form.productSTDelivery;
    var deliveryWithin = '';



    var standardDelivery = "LKR " + standardDelivery_value;




    let details = {
      'id': this.id,
      'status': 'NEW',
      'title': productTitle,
      'category': category,
      'sub_category': subcategory,
      'product_company': productCompany,
      'product_price_before_discount': productPrice,
      'product_price': price_value,
      'simple_description': simple_description,
      'product_description': productDesciption,
      'shipping_charge': shippingCharge,
      'product_availability': availability,
      'warrenty': warrenty,
      'sale_type': saleType,
      'stock_available': availableStock,
      'height': height,
      'width': width,
      'length': length,
      'volumetric_weight': volumetric_weight,
      'weight': weight,

      'otherProductDetails': this.doneOtherDetialsList,
      'productInformation': this.productInformationList,
      'additionalInformation': this.productAdditonalInfoList,
      'otherImages': this.data.otherImages,
      'detailsLink': this.data.detailsLink,

      'standardDelivery_value': standardDelivery_value,
      'standardDelivery': standardDelivery,
    }


    const uploadFormData = new FormData();
    uploadFormData.append('details', JSON.stringify(details));
    this.productService.editDetails(uploadFormData).subscribe(res => {
      var status = res.status;
      this.docId = res.doc_id;
      if (status == 'success') {
        Swal.fire({
          title: 'Successfully updated the product!',
        }).then((result) => {
        })
      } else {
        Swal.fire({
          title: 'Error with updating the product!',
        }).then((result) => {

        })
      }
    })
  }

  //************ Upload Product Images */

  //************* Upload Main Image *********************8 */
  public selectedMainImage: any = File;
  onFileSelectedMainImg(event: any) {
    const file = event.target.files[0];
    this.selectedMainImage = file;
  }

  saveMainImage(fileInput: HTMLInputElement) {
    //this.docId = "4EhBRtgQQjM6kmxUcVYD";
    const uploadFormData = new FormData();
    uploadFormData.append('file', this.selectedMainImage);

    if (this.id == 0) {
      Swal.fire({
        title: 'Please save product details to add images!',
      }).then((result) => {
      })
    } else {
      this.productService.uploadMainImage(uploadFormData, this.id,this.data.mainImageName).subscribe(res => {
        var status = res.status;
        if (status == 'success') {
          Swal.fire({
            title: 'Successfully updated the product main image!',
          }).then((result) => {
            fileInput.value = '';
            location.reload();
          })
        } else {
          Swal.fire({
            title: 'Error with updating the product!',
          }).then((result) => {
            fileInput.value = '';
            //location.reload();
          })
        }
      })
    }

  }
  //***********Other Images ***************************** */
  public otherImages: any = File;
  otherImagesList: Array<any> = [];
  otherImagesNameList: Array<any> = [];
  onFileSelectedOtherImages(event: any) {
    const file = event.target.files[0];
    this.otherImages = file;
  }

  addImagesOtherToList(fileInput: HTMLInputElement,img:any) {
    const uploadFormData = new FormData();
    uploadFormData.append('file', this.otherImages);

    this.productService.uploadOtherImage(uploadFormData, this.id,img).subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Successfully updated the product image!',
        }).then((result) => {
          location.reload();
        })
      } else {
        Swal.fire({
          title: 'Error with updating the product!',
        }).then((result) => {
          fileInput.value = '';
        })
      }
    })

  }

  deleteImage(img:any){
    this.productService.deleteOtherImages("",this.id,img).subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Successfully deleted the product image!',
        }).then((result) => {
          location.reload();
        })
      } else {
        Swal.fire({
          title: 'Error with deleting the product image!',
        }).then((result) => {
        
        })
      }
    })
  }

}
