import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { MainCategory } from '../../model/main_category';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-main-category',
  templateUrl: './edit-main-category.component.html',
  styleUrl: './edit-main-category.component.scss'
})
export class EditMainCategoryComponent {
  constructor(private maserfilesService: MaserfilesService, private route: ActivatedRoute) { }
  maincategory!: MainCategory;

  id: number = 0;
  data: any;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.maserfilesService.getDetails('category', this.id).subscribe(res => {
        this.data = res.data;
        console.log("category details " + JSON.stringify(res));

      })
    })
  }

  submit(form) {
    var name = form.name;
    console.log(name);
    var description = form.description;
    console.log(description);
    this.maincategory = new MainCategory(this.id, name, description, '', '');
    this.maserfilesService.update(this.maincategory, 'category').subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Success!',
        }).then((result) => {
          location.reload();
        })
      } else {
        Swal.fire({
          title: res.message,
        }).then((result) => {
          //  location.reload();
        })

      }
    })
  }
}
