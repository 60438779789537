<app-navbar-style-one></app-navbar-style-one>

<div style="background-color: white;">
    <div class="container">
        <div class="row">
            

            
            <div class="col-lg-12 col-md-12">
                <div class="container" style="padding-top: 4%;">
                    <div class="row" *ngIf="userCartDetailsListSize==0">
                        <div class="col">
                            <h2 style="text-align: center;">No Active Customers</h2>
                            <br>

                        </div>
                    </div>
                    <div class="row" *ngIf="userCartDetailsListSize>0">
                        <h2 style="text-align: center;">Active Customers</h2>
                            <br>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <ngx-datatable #table class="material" [columns]="cols" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                                [rows]="userCartDetailsList.slice(page.pageNumber * page.size, (page.pageNumber + 1) * page.size)"
                                [limit]="page.size">
                                <ngx-datatable-column name="Mobile Number" prop="customer_mobile">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="font-semibold">+{{row.customer_mobile }}</div>

                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Package" prop="package_name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="font-semibold">{{row.package_name }}</div>

                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Status" prop="Status">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="flex items-center gap-2">
                                            <div class="font-semibold">{{row.subscription_status }}</div>

                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Activation Date" prop="created_at">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div class="flex items-center gap-2">
                                            <div class="font-semibold">{{row.created_date + " " + row.created_time}}</div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                            </ngx-datatable>
                            <div class="pagination-controls">
                                <button (click)="prevPage()" [disabled]="page.pageNumber === 0">Previous</button>
                                <span>{{page.pageNumber + 1}} / {{totalPages}}</span>
                                <button (click)="nextPage()"
                                    [disabled]="page.pageNumber >= totalPages - 1">Next</button>
                            </div>
                          
                        </div>
                        <!--div class="col-lg-2"></div>
                        <div class="col-lg-10 col-md-12 col-sm-12">
                            <table style="border-bottom: 1px solid black;">
                                <thead style="border-bottom: 1px solid black;">
                                    <tr>
                                        <th><h6>Subscription</h6></th>
                                        <th><h6 style="text-align: center;padding: 2px;">Activation Date</h6></th>
                                        <th><h6 style="text-align: center;padding: 2px;">Status</h6></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr *ngFor="let subscription of userCartDetailsList">
                                        <td>
                                            <p class="card-title">
                                             {{subscription.package_name}} Subscription </p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;padding: 2px;"> {{subscription.created_date + " " + subscription.created_time}} </p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;padding: 2px;"> {{subscription.subscription_status}}</p>
                                        </td>

                                    </tr>
                                    <br>
                                   
                                </tbody>
                            </table>
                        </div-->
                    </div>
                    

                </div>
            </div>
            
        </div>
    </div>
</div>