import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LoginServiceService } from 'src/app/components/admin_service/login-service.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-kitchen-room-products',
    templateUrl: './kitchen-room-products.component.html',
    styleUrls: ['./kitchen-room-products.component.scss']
})
export class KitchenRoomProductsComponent implements OnInit {

    constructor(private adminLogin:LoginServiceService) { }

    ngOnInit(): void {
    }

    submit(form){
        var username = form.username;
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        let details = {
            'username':username,
            'email':email,
            'password':password,  
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.adminLogin.registerAdmin(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                   
                  })
            }else{
                Swal.fire({
                        title: 'Error of registering admin',
                      }).then((result) => {
                        location.reload();
                })
                
            }
        })
    }

}