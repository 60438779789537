<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<!-- Start Wishlist Area -->
<section class="wishlist-area ptb-100">
    <div class="container">
        <div class="row" *ngIf="userWishListSize==0">
            <div class="col">
               <h2 style="text-align: center;">No items on the wish list.</h2> 
               <br>
               <h4  *ngIf="token==null || token=='empty' " style="text-align: center;"><a routerLink="/login" style="color: chocolate;">Click Here to Login</a></h4>
            </div>
        </div>
        <form *ngIf="userWishListSize>0">
            <div class="wishlist-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Stock Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let details of userWishList">
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/{{details.detailsLink}}"><img [src]="details.mainImg" alt="item"></a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/{{details.detailsLink}}">{{details.title}}</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">{{details.price}}</span>
                            </td>

                            <td class="product-stock-status">
                                <span class="in-stock"><i class='bx bx-check-circle'></i> {{details.stock}}</span>
                            </td>

                            <td class="product-subtotal">
                                <button type="submit" (click)="addToCart(details.id,1)" class="default-btn" ><i class='bx bxs-cart-add'></i> ADD TO CART</button>
                                <button type="submit" class="remove" (click)="removeItemFromWishList(details)"><i class='bx bx-trash'></i></button>
                            </td>
                       </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</section>
<!-- End Wishlist Area -->