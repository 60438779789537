<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
   <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-4">
        <img src="assets/img/Tedi-new.gif" style="height: 200px;">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
        <h1 style="text-align: center;">{{Content.title}}</h1>
    </div>
    <div  class="col-lg-4 col-md-4 col-sm-4" >
        
    </div>
   </div>
    
  
    
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" >
            <span class="sub-title"></span>
            <h2>About us.</h2>
            <p>Originally derived from the love of a huggable personality, TeDi is today popularly known as TeDiSmart, that is powered by world’s No.1 Smart Home platform Tuya. TeDiSmart is a Next-Generation Technology Brand that helps enterprises reimagine their businesses for the digital age using the Next Gen technology that is powered by Tuya. Be it your home, office space, warehouse or apartment – TeDiSmart have you covered with its most latest and innovative smart product range. Our technology, products and services are built on the theory of continuously developing innovation, with a world-renowned management philosophy, a strong culture of invention and risk-taking that is relentlessly focused on customer relationships.</p>
            <p>TeDiSmart also takes pride in its many diversities, social responsibility, sustainability, and education initiatives.
                TeDiSmart devices can be effortlessly integrated with customers other devices at home, office or while camping and can be operated with a simple voice command giving new meaning to the word “convenience”.</p>

            
        </div>

       
    </div>
</section>
<!-- End About Area -->

<!--app-why-choose-us></app-why-choose-us>

<app-video></app-video>

<app-testimonials></app-testimonials>

<app-facility-style-one></app-facility-style-one-->