import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../../common_service/userlogin.service';
import Swal from 'sweetalert2';
import { customerModel } from '../../model/customerModel';
import { VerificationModel } from '../../model/VerificationModel';
import { DialogService } from '../../common_service/dialog.service';

@Component({
  selector: 'app-home-demo-two',
  templateUrl: './home-demo-two.component.html',
  styleUrls: ['./home-demo-two.component.scss']
})
export class HomeDemoTwoComponent implements OnInit {
 
  token:any;
  accShow:any;
    constructor(private userlogin:UserloginService,private dialogService:DialogService) {
      
      this.token = localStorage.getItem('token');
      this.accShow = localStorage.getItem('accShow');
      console.log("token "+this.token);
      console.log("accShow "+this.accShow);
      if( this.token!=null && this.accShow=='show'){
        this.dialogService.checkTokenExpiration(null).subscribe(res=>{
          if(res.status=='success'){
            localStorage.setItem('token','empty');
          localStorage.setItem('accShow','notShow');
          Swal.fire({
            title: res.message,
          }).then((result) => {
            location.reload();
          })
          }
          
        })
      }
     }

     isLoginVisible: boolean = false;

     showLogin() {
       this.isLoginVisible = true;
     }
  

  ngOnInit(): void {
   
  }

  //*************************Login Start *******************************/
  
 

  loginToSystem(email:any,password:any){
    if(email==''){
      Swal.fire({
        title: 'Please enter Email!',
      }).then((result) => {
        
      })
    }else if(password==''){

      Swal.fire({
        title: 'Please enter Password!',
      }).then((result) => {
        
      })
    }else{
    this.customerModel = new customerModel(0,email,password,"","");
    
    this.userlogin.loginUser( this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          let token = res.data;
          localStorage.setItem('token',token);
          localStorage.setItem('type','customer');
          localStorage.setItem('accShow','show');
          let packageSelected = localStorage.getItem('package');
          if(packageSelected!='NO'){
            window.location.href='selectpackage/'+packageSelected;
          }else{
            location.reload();
          }
;          
          
        }
        
    })
    }
  }

  loginFormDisplay:boolean=true;
  registerClicked(){
    this.loginFormDisplay=!this.loginFormDisplay;
  }

  customerModel!:customerModel;
  verificationModel!:VerificationModel;
  registerButtonClicked:boolean=false;
  customerRegisteredEmail:any;
  registerUser(email:any,password:any){
    if(email==''){
      Swal.fire({
        title:'Please enter email!',
      }).then((result) => {
       
      })
    }else if(password==''){
      Swal.fire({
        title:'Please enter password!',
      }).then((result) => {
       
      })
    }else{
     
    this.customerModel = new customerModel(0,email,password,"","");
    this.userlogin.registerUser(this.customerModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
         
        })

        if(status=='success'){
          this.customerRegisteredEmail = email;
          this.registerButtonClicked = true;
        }
        
    })
    }
  }

  verifyAccount(emailcode:any){
     if(emailcode==''){
      Swal.fire({
        title:'Please enter email verification code!',
      }).then((result) => {
       
      })
    }else{
    this.verificationModel = new VerificationModel( this.customerRegisteredEmail,emailcode,'',0);
    this.userlogin.verification(this.verificationModel).subscribe(res=>{
        var status = res.status;
        Swal.fire({
          title:res.message,
        }).then((result) => {
          
        })

        if(status=='success'){
          this.registerClicked();
        }

        
        
    })
    }
  }

}
