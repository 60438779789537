import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { SubCategory } from '../../model/sub_category';
import { MaserfilesService } from '../../admin_service/maserfiles.service';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrl: './add-sub-category.component.scss'
})
export class AddSubCategoryComponent {
  categoryList:Array<any>=[];
  constructor(private maserfilesService: MaserfilesService) {
    maserfilesService.getAll('category').subscribe(res=>{
      this.categoryList = res;
      console.log("main categories "+JSON.stringify(res));
    })
   }
  subCategory!: SubCategory;
  selectedCategory:number=0;
  submit(form) {
    var subcategory = form.subcategory;
    console.log(subcategory);
  
    this.subCategory = new SubCategory(0, this.selectedCategory, subcategory, '', '');
    this.maserfilesService.create(this.subCategory, 'subcategory').subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Success!',
        }).then((result) => {
          location.reload();
        })
      } else {

        Swal.fire({
          title: res.message,
        }).then((result) => {
          //location.reload();
        })

      }
    })
  }

  selectCategory(id:any){
    this.selectedCategory = id;
  }

}
