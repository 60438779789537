
<app-navbar-style-three></app-navbar-style-three>
<br>
<div class="section-title">
    <h4>Edit Category</h4>
</div>

<section class="order-tracking-area">
    <div class="container">
        <div class="order-tracking-content">
            <form #contactForm="ngForm">
                <div class="form-group">
                    <label>Enter Category Name</label>
                    <input required minlength="3" maxlength="50" ngModel name="name" [ngModel]="this.data.name" type="text" #name="ngModel"  class="form-control" id="name" placeholder="Enter Category">
                </div>

                <div class="form-group">
                    <label>Enter Description</label>
                    <textarea required ngModel name="description" #description="ngModel" [ngModel]="this.data.description" class="form-control" id="description" placeholder="Enter Description"></textarea>
                </div>
            </form>
        </div>
    </div>
</section>
