<app-navbar-style-two></app-navbar-style-two>
<div class="container">
    <div class="row">
        <aside class="col-md-4 col-lg-3">
            <ul class="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="tab-dashboard-link" data-toggle="tab" href="#tab-dashboard" role="tab" aria-controls="tab-dashboard" aria-selected="true">Orders</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="tab-subscription-link" data-toggle="tab" href="view/subscription" role="tab" aria-controls="tab-subscription" aria-selected="false">Subscription</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="tab-password-link" data-toggle="tab" href="#tab-password" role="tab" aria-controls="tab-password" aria-selected="false">Change Password</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="service/controller/usesr_controller.php/singout">Sign Out</a>
                </li>
            </ul>
        </aside><!-- End .col-lg-3 -->
        
        <div class="col-md-8 col-lg-9">
            <div class="tab-content">
               
                <div class="tab-pane fade" id="tab-password" role="tabpanel" aria-labelledby="tab-password-link">
                    <div class="login-form">
                        <h2 style="text-align: center; margin-top: 10px;">Change Password</h2>
                        <form #contactForm="ngForm" style="padding-left: 10px; padding-right: 10px;">
                            <div class="form-group">
                                <label>Email</label>
                                <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email" readonly [(ngModel)]="customerModel.email">
                            </div>
                            <div class="form-group">
                                <label>Current Password *</label>
                                <input required minlength="3" maxlength="10" ngModel name="curpassword" type="password" #curpassword="ngModel" class="form-control" id="curpassword" placeholder="Enter Current Password">
                            </div>
                            <div class="form-group">
                                <label>Enter New Password *</label>
                                <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                            </div>
                            <div class="form-group">
                                <label>Confirm Password *</label>
                                <input required minlength="3" maxlength="10" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                            </div>
                            <button type="submit" (click)="changePassword(email.value, curpassword.value, password.value, confirmpassword.value)">SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            <!--div class="container">
               
                <div class="row" >
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="login-form">
                            <h2 style="text-align: center;margin-top: 10px;">Change Password</h2>
                
                            <form #contactForm="ngForm" style="padding-left: 10px;padding-right: 10px;">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email" readonly [(ngModel)]="customerModel.email">
                                </div>
                                <div class="form-group" >
                                    <label>Current Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="curpassword" type="password" #curpassword="ngModel" class="form-control" id="curpassword" placeholder="Enter Current Password">
                                </div>
                                <div class="form-group" >
                                    <label>Enter New Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                </div>
                                <div class="form-group" >
                                    <label>Confirm Password *</label>
                                    <input required minlength="3" maxlength="10" ngModel name="confirmpassword" type="password" #confirmpassword="ngModel" class="form-control" id="confirmpassword" placeholder="Confirm Password">
                                </div>
                
                                
                                <button type="submit"  (click)="changePassword(email.value,curpassword.value,password.value,confirmpassword.value)">SUBMIT</button>
                            </form>
                          
                        </div>
                    </div>
                    <div class="col-lg-3"></div>
                </div>

             
            </div-->
  