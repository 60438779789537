import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getPaymentDetails(details:any){
    let token = localStorage.getItem("token");
    return this.httpClient.post<any>(this.backendService.apiLocalSystem+'payment/history?token='+token,details);
  }
  //**************************8 */
  checkpaymentstatus(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/checkpaymentstatus',details);
  }

  viewOrdersOfUser(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getuserpreorders',details);
  }
}
