import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginServiceService } from '../../admin_service/login-service.service';
import { MaserfilesService } from '../../admin_service/maserfiles.service';
import { MainCategory } from '../../model/main_category';

@Component({
  selector: 'app-add-main-category',
  templateUrl: './add-main-category.component.html',
  styleUrl: './add-main-category.component.scss'
})
export class AddMainCategoryComponent {
  constructor(private maserfilesService: MaserfilesService) {
    
   }
  maincategory!: MainCategory;
  submit(form) {
    var name = form.name;
    console.log(name);
    var description = form.description;
    console.log(description);
    this.maincategory = new MainCategory(0, name, description, '', '');
    this.maserfilesService.create(this.maincategory, 'category').subscribe(res => {
      var status = res.status;
      if (status == 'success') {
        Swal.fire({
          title: 'Success!',
        }).then((result) => {
          location.reload();
        })
      } else {
        Swal.fire({
          title: res.message,
        }).then((result) => {
          //location.reload();
        })

      }
    })
  }

}
