import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserloginService } from '../../common_service/userlogin.service';
import { PaymentService } from '../../common_service/payment.service';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    constructor(private userlogin:UserloginService,private paymentService:PaymentService) { }

    type:string='Orders';

    
    isActive(item: string): boolean {
        return this.type === item;
    }

    showThis(type:any){
        this.type=type

        if(type=='LogOut'){
                localStorage.setItem('token','empty');
                localStorage.setItem('accShow','notShow');
               window.location.href="/";
             
        }
    }

    billingAddressObj:any;
    shippingAddressObj:any;
    userName:any;
    emailval:any;
    preOrderList:any;
    preOrderListSize:any;
    ngOnInit(): void {
      let token = localStorage.getItem('token');
      if(token==null || token=='null'){
        Swal.fire({
            title: 'Please log into the system!',
          }).then((result) => {
            
          })
      }else{
        let details = {
            'token':token,
          }
          const uploadFormData = new FormData();
          uploadFormData.append('details', JSON.stringify(details));
         this.userlogin.getUserAddresses(uploadFormData).subscribe(res=>{
            this.billingAddressObj = res.billingAddress;
            this.shippingAddressObj = res.shippingAddress;
        })

        this.userlogin.getUserDetails(uploadFormData).subscribe(res=>{
            let status = res.status;
            if(status=='success'){
                let userDetails = res.user_data;
                this.userName = userDetails.username;
                this.emailval = userDetails.email;
            }
        })
        this.userlogin.getUserDetails(uploadFormData).subscribe(res=>{
            let status = res.status;
            if(status=='success'){
                let userDetails = res.user_data;
                this.userName = userDetails.username;
                this.emailval = userDetails.email;
            }
        })

        this.paymentService.viewOrdersOfUser(uploadFormData).subscribe(res=>{
            let invoiceDetails = res;
            this.preOrderList = invoiceDetails.invoiceList;
            this.preOrderListSize = this.preOrderList.length;
           // console.log("  this.preOrderList "+ this.preOrderList.length);
            //console.log("viewOrdersOfUser "+JSON.stringify(this.preOrderList));
        })



      }
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Order Tracking'
        }
    ]

    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        let details = {
            'email':email,
            'password':password,  
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.registerUser(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                    var token = res.token;
                    localStorage.setItem('token',token);
                    window.location.href = "/login";
                  })
            }else{
                if(status == 'registered_email'){
                    Swal.fire({
                        title: 'Email already exit',
                      }).then((result) => {
                        
                      })
                }else{
                    Swal.fire({
                        title: 'Error',
                      }).then((result) => {
                       
                      })
                }
            }
        })
    }


}