import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendURLService } from '../admin_service/backend-url.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient:HttpClient,private backendService:BackendURLService) { }

  getOrderDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getorderdetailsforcheckout',details);
  }

  getCheckoutToken(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/getcheckouttoken',details);
  }

  updateCheckOutAddress(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'user/updatecheckoutaddress',details);
  }
}
