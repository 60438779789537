import { CanActivateFn, UrlTree } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";

export const GuardSuperAdmin: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const loginType = localStorage.getItem('userType');
    if (loginType === 'SUPER_ADMIN') {
        return true;
    } else {
        return false; // or you can redirect to another URL here
    }
};

export const GuardAdmin: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const loginType = localStorage.getItem('userType');
    if (loginType === 'ADMIN') {
        return true;
    } else {
        return false; // or you can redirect to another URL here
    }
};


export const GuardUser: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const loginType = localStorage.getItem('userType');
    if (loginType === 'USER' || loginType === 'ADMIN') {
        return true;
    } else {
        return false; // or you can redirect to another URL here
    }
};