export class customerModel {
    id: number;
    email: string;
    password:string;
    mobile:string;
    created_at: string;
   

    constructor(
        id: number,
        email:string,
        password:string,
        mobile:string,
        created_at:string

    ) {
        this.id = id;
        this.email = email;
        this.password = password;
        this.mobile = mobile;
        this.created_at = created_at;
        
       
       
    }

    getId(): number {
        return this.id;
    }
    setId(id: number): void {
        this.id = id;
    }
    getPassword(): string {
        return this.password;
    }
    setPassword(password: string): void {
        this.password = password;
    }
    getCreatedAt(): string {
        return this.created_at;
    }
    setCreatedAt(created_at: string): void {
        this.created_at = created_at;
    }

  
  
    getEmail(): string {
        return this.email;
    }
    setEmail(email: string): void {
        this.email = email;
    }


    getMobile(): string {
        return this.mobile;
    }
    setMobile(mobile: string): void {
        this.mobile = mobile;
    }
    


}
