<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<!--div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
</div-->
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">
        <div class="row" *ngIf="userCartDetailsListSize==0">
            <div class="col">
                <h2 style="text-align: center;">No items on the cart.</h2>
                <br>
                <h4 *ngIf="token==null || token=='empty' " style="text-align: center;"><a routerLink="/"
                        style="color: chocolate;">Click Here to Login</a></h4>
            </div>
        </div>
        <div class="row" *ngIf="userCartDetailsListSize>0">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="card packages" >
                    <div class="card-body">
                        <div class="text-center p-3">
                            <h5 class="card-title"><img style="max-width: 100%;"
                                    src="/assets/img/zonealarm/powerdbydialog.jpg"> {{userCartDetailsList.package_name}} </h5>
                            <small>Billing {{userCartDetailsList.package_name}}</small>
                            <br><br>
                            <span class="h6"> {{userCartDetailsList.price}}</span>/month
                            <br>
                            <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">
                                {{userCartDetailsList.old_price}}</span>
                            <br><br>

                        </div>
                    </div>


                </div>
                <!--div class="card packages" *ngIf="packageName=='Annually'">
                    <div class="card-body">
                        <div class="text-center p-3">
                            <h5 class="card-title"><img style="max-width: 100%;"
                                    src="assets/img/zonealarm/powerdbydialog.jpg"> Annually</h5>
                            <small>Billing Annually</small>
                            <br><br>
                            <span class="h6">Rs 3162.50+TAX</span>/year
                            <br>
                            <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">LKR
                                8,200.00</span>
                            <br><br>
                        </div>

                    </div>
                </div-->
                <!--form>
                    <div class="cart-table table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
        
                            <tbody *ngFor="let cartDetails of userCartDetailsList">
                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/simple-product"><img [src]="cartDetails.mainImg" alt="item"></a>
                                    </td>
        
                                    <td class="product-name">
                                        <a routerLink="/simple-product">{{cartDetails.title}}</a>
                                    </td>
        
                                    <td class="product-price">
                                        <span class="unit-amount">{{cartDetails.price}}</span>
                                    </td>
        
        
                                    <td class="product-quantity">
                                        <span class="minus-btn" (click)="decreaseQuantity(cartDetails)"><i class='bx bx-minus'></i></span>
                                        <input type="text" min="1" [value]="cartDetails.quantity" readonly style="width: 30px;border: none;text-align: center;">
                                        <span class="plus-btn" (click)="increaseQuantity(cartDetails)"><i class='bx bx-plus'></i></span>
                                    </td>
        
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">{{cartDetails.totoalPrice}}</span>
                                        <button type="submit" class="remove" (click)="removeItemFromCart(cartDetails)"><i class='bx bx-trash'></i></button>
                                     
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>         
                </form-->
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="cart-totals summary">
                    <h5>Your Order</h5>
                    <hr>
                    <ul>
                        <li>Product <span>Total</span></li>
                        <li>ZoneAlarm Mobile Security <span>LKR {{userCartDetailsList.price_with_tax}} </span></li>
                        <li>Total <span>LKR  {{userCartDetailsList.price_with_tax}}</span></li>
                    </ul>
                    <p style="color:black"> * payment will be add to bill, prepaid customer's must have above balance
                    </p>
                    <div style="padding-bottom: 10px;">
                        <input type="checkbox" [(ngModel)]="termsAccepts"
                            id="payment-policy">
                        <label  for="payment-policy" style="padding-left: 0.6rem;">I Accept the <a target="_blank" style="color: rgb(35, 107, 31);"
                                href="https://www.zonealarm.com/privacy">Terms and Conditions </a> *</label>
                    </div>
                    <button class="default-btn" style="width: 100%" (click)="checkoutClicked()" *ngIf="showPlaceOrderBtn">Place Order</button>
                   

                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Cart Area -->