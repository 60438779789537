<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;" style="margin-bottom:0 ;">
        <h2>{{Content.title}}</h2>
        <h5>{{Content.paragraph}}</h5>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="card single-products-item2">
                <div class="card-body text-center">
                    <div class="products-content">
                        <i class="fa fa-wifi" style="font-size: 40px;"></i>
                        <h3>Connect to Wi-Fi <br>worry-free</h3>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="card single-products-item2">
                <div class="card-body text-center">
                    <div class="products-content">
                        <i class="fa fa-cog" style="font-size: 40px;"> </i>
                        <h3>Protect your privacy and data from hackers</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="card single-products-item2">
                <div class="card-body text-center">
                    <div class="products-content">
                        <i class="fa fa-user-secret" style="font-size: 40px;"></i>
                        <h3>Shield your device from hostile takeovers</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-lg-12" class="moredetails">
            <!--a href="#zoneDetails" style="color: green; text-decoration: underline;">Click here for more details</a-->
            <br><br>
            <h6 style="text-align: center; color: red; font-size: 20px; font-weight: 600; ">
                *Exclusive for Dialog Prepaid
                <br>
                &
                <br>
                Postpaid Customer
            </h6>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-2 col-sm-12 col-md-12">

        </div>
        <div class="col-lg-4 col-md-12 mb-4">
            <div class="card h-100 packages">
                <div class="card-body">
                    <div class="text-center p-3">
                        <h5 class="card-title"><img style="max-width: 100%;"
                                src="/assets/img/zonealarm/powerdbydialog.jpg"> Monthly </h5>
                        <small>Billing Monthly</small>
                        <br><br>
                        <span class="h6">{{productPriceModel.month_price}}</span>/month
                        <br>
                        <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">
                            {{productPriceModel.month_old_price}} </span>
                        <br><br>

                    </div>
                </div>

                <div class="card-body text-center">
                    <button class="btn btn-outline-primary btn-lg"
                        style="border-radius: 30px; border-color: red; color: red;" data-bs-toggle="modal"
                        data-bs-target="#productsQuickView2"  *ngIf="token==null || token=='empty' "
                        (click)="selectPackege('Monthly')">Select</button>


                    <button class="btn btn-outline-primary btn-lg"
                        style="border-radius: 30px; border-color: red; color: red;"
                        *ngIf="token!=null && token!='empty' && accShow=='show'"
                        (click)="checkExpiration('Monthly')">Select</button>

                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12 mb-4">
            <div class="card h-100 packages">
                <div class="card-body">
                    <div class="text-center p-3">
                        <h5 class="card-title"><img style="max-width: 100%;"
                                src="assets/img/zonealarm/powerdbydialog.jpg"> Annually</h5>
                        <small>Billing Annually</small>
                        <br><br>
                        <span class="h6">{{productPriceModel.annual_price}}</span>/year
                        <br>
                        <span class="h6" style="text-decoration: line-through; text-decoration-color: red;">
                            {{productPriceModel.annual_old_price}}</span>
                        <br><br>
                    </div>

                </div>

                <div class="card-body text-center">
                    <button class="btn btn-outline-primary btn-lg"
                        style="border-radius: 30px; border-color: red; color: red;" data-bs-toggle="modal"
                        data-bs-target="#productsQuickView2"  *ngIf="token==null || token=='empty' "
                        (click)="selectPackege('Annually')">Select</button>


                    <button class="btn btn-outline-primary btn-lg"
                        style="border-radius: 30px; border-color: red; color: red;"
                        *ngIf="token!=null && token!='empty' && accShow=='show' "
                        (click)="checkExpiration('Annually')">Select</button>


                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-md-6">

        </div>
    </div>

    <br>
    <br>
    <div class="row" id="zoneDetails">
        <div class="col-lg-12 col-sm-12 col-md-12">
            <h4 style="text-align:center" > ZoneAlarm Security Features. </h4>
            <br>


            <table class="tableFeatures">
                <tr>
                    <th><b>Platform</b></th>
                    <th><b>Feature</b></th>
                    <th><b>Description</b></th>
                </tr>
                <!-- Android OS Features -->
                <tr>
                    <td rowspan="9"><b>Android OS</b></td>
                    <td>Wi-Fi Protection (Man-in-the-Middle)</td>
                    <td>Protects traffic from being trapped when connected to Wi-Fi</td>
                </tr>
                <tr>
                    <td>Wi-Fi Ranking</td>
                    <td>Ranks the level of security of the connected hot spot</td>
                </tr>
                <tr>
                    <td>Safe Browsing</td>
                    <td>Blocks known malicious sites</td>
                </tr>
                <tr>
                    <td>Phishing Protection</td>
                    <td>Blocks known phishing sites</td>
                </tr>
                <tr>
                    <td>Zero-Day Phishing Protection</td>
                    <td>Protects from both known and unknown phishing sites</td>
                </tr>
                <tr>
                    <td>App Protection</td>
                    <td>Protects from viruses, malware, and ransomware</td>
                </tr>
                <tr>
                    <td>Anti-BOT</td>
                    <td>Blocks viruses from communicating with Command and Control sites</td>
                </tr>
                <tr>
                    <td>Operating System Protection</td>
                    <td>Detects if the Operating System has been rooted</td>
                </tr>
                <tr>
                    <td>Personal Reporting</td>
                    <td>Presents security incident report once a week</td>
                </tr>
        
                <!-- IOS Features -->
                <tr>
                    <td rowspan="6"><b>iOS</b></td>
                    <td>Wi-Fi Protection (Man in the Middle)</td>
                    <td>Protects traffic from being trapped when connected to Wi-Fi</td>
                </tr>
                <tr>
                    <td>Wi-Fi Ranking</td>
                    <td>Ranks the level of security of the connected hot spot</td>
                </tr>
                <tr>
                    <td>SMS Phishing Protection</td>
                    <td>Block Phishing sites which have not been seen before</td>
                </tr>
                <tr>
                    <td>Side-loaded App Protection</td>
                    <td>Detects viruses from apps downloaded outside of the App Store</td>
                </tr>
                <tr>
                    <td>Operating System Protection</td>
                    <td>Detects if the Operating System has been rooted</td>
                </tr>
            </table>
        </div>
    </div>
</div>