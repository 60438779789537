import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../../common_service/userlogin.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-order-tracking-page',
    templateUrl: './order-tracking-page.component.html',
    styleUrls: ['./order-tracking-page.component.scss']
})
export class OrderTrackingPageComponent implements OnInit {

    constructor(private userlogin:UserloginService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Order Tracking'
        }
    ]

    submit(form){
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

        var username = form.username;

        let details = {
            'email':email,
            'password':password,  
            'username':username
        }
        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
        this.userlogin.registerUser(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                    var token = res.token;
                    localStorage.setItem('token',token);
                    window.location.href = "/login";
                  })
            }else{
                if(status == 'registered_email'){
                    Swal.fire({
                        title: 'Email already exit',
                      }).then((result) => {
                        
                      })
                }else{
                    Swal.fire({
                        title: 'Error',
                      }).then((result) => {
                       
                      })
                }
            }
        })
    }


}